/* This example requires Tailwind CSS v3.0+ */

export default function About() {

    return (
        <section >
          <div className="pt-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-24 lg:px-12">

<h1 className="mb-4 text-4xl md:text-5xl lg:text-6xl hero-text">“Experience the difference”</h1>
<p className="mb-4  text-lg lg:text-xl sm:px-16 xl:px-48 hero-sub">Zibon is a technology company solving the problem of Africans who travel from store to store in search of a product.</p>

</div>
          <div className="lg:py-16">  
          <div className="pt-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-10 lg:px-12">

<h1 className="mb-4 text-4xl md:text-5xl story-header">Founder’s Story</h1>

</div>
  <div className="mx-auto lg:w-[80%] px-10 py-10">
      <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
        <div className="md:5/12 lg:w-5/12">
          <img src="images/founder.png" alt="image" loading="lazy" width="" height=""/>
        </div>
        <div className="md:7/12 lg:w-6/12">          
          <p className="mt-6 story-body">Growing up with my twin… I get nervous each time my mom would send us on an errand to get a product that is most times unavailable. It’s either the product is finished or we’re redirected to another store. When I leave to the recommended store, the same cycle continues. </p>
          <p className="mt-4 story-body"> As an adult, I realized that others experienced same while searching for a product at various stores. </p>
          <p className="mt-4 story-body"> I developed a phobia for physical shopping until online shopping became popular. It solved the problem to a reasonable extent, however, in order to proffer further support and make my life and others better, I decided to create Zibon.</p>
          <p className="mt-4 text-gray-600">
            
          </p>
          <ul className="list-disc">
  <li className="story-body">Our vision is to be the company that empowers customers with the information and resources they need to make informed purchasing decisions and find the products they want, right at their fingertips. </li>
  <li className="story-body">Our mission is to support local businesses, build stronger communities, and provide customers with a unique and convenient shopping experience.
</li>
</ul>
        </div>
      </div>
  </div>
</div>

        </section>
    )
}
