import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/AboutUs';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Policy from './pages/Privacy';


function App() {
  return (
    <div className="App">
      <Router>  
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy-policy' element={<Policy />} />
          <Route path='/contact-us' element={<Contact />} />
        </Routes>
      </Router>
    </div>

  );
}

export default App;
