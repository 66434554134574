/* This example requires Tailwind CSS v3.0+ */

/* This example requires Tailwind CSS v3.0+ */

import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";


class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
    };
    this.form = React.createRef();
  }

 

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

  
    //Name
    if (!fields["lastname"]) {
      formIsValid = false;
      errors["lastname"] = "Name Cannot be empty";
    }

    if (typeof fields["lastname"] !== "undefined") {
      if (!fields["lastname"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["lastname"] = "Name should contain only letters";
      }
    }

   

     //message
     if (!fields["message"]) {
      formIsValid = false;
      errors["message"] = "Message cannot be empty";
    }

  


    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email Cannot be empty";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  contactSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      emailjs
      .sendForm(
        "service_1bxrhij",
        "template_7jntgur",
        this.form.current,
        "k0m4f5lysi0rpsLi_"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message Sent");
          this.form.reset();
        },
        (error) => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
      
    } else {
      alert("Form has errors.");
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  render() {
    return (
      <section id="emailus" className="bg-white">

        <div className="bg-[#FBFBFB] md:px-20 py-6">
          <div className=" bg-white rounded-md px-6 py-10 max-w-4xl mx-auto">
          <div className="container mx-auto flex flex-col px-6 py-12">
              <div className="flex-1 lg:-mx-6 lg:flex lg:items-center">
              <div className="text-black lg:mx-6 lg:w-1/2">
              <p className="mt-6 contact-subheader">Get in touch</p>
                  <h1 className="contact-header">Be a part of the FUTURE</h1>
      
                  <p className="mt-6 contact-tagline">We’re bringing the human touch back to social commerce and strengthening the social fabric of our neighborhoods. Join us in our mission to support local economies and build a more connected community. Be a part of the future of commerce!</p>
      
                  <div className="mt-6 space-y-8 md:mt-8">
                  <div className="flex items-start ">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#F89A33]">
            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 16.0347C1.45 16.0347 0.979002 15.839 0.587002 15.4477C0.195002 15.0563 -0.000664969 14.5853 1.69779e-06 14.0347V2.03467C1.69779e-06 1.48467 0.196002 1.01367 0.588002 0.62167C0.980002 0.22967 1.45067 0.034003 2 0.0346697H18C18.55 0.0346697 19.021 0.230669 19.413 0.622669C19.805 1.01467 20.0007 1.48534 20 2.03467V14.0347C20 14.5847 19.804 15.0557 19.412 15.4477C19.02 15.8397 18.5493 16.0353 18 16.0347H2ZM10 8.85967C10.0833 8.85967 10.171 8.84734 10.263 8.82267C10.355 8.798 10.4423 8.76034 10.525 8.70967L17.6 4.28467C17.7333 4.20134 17.8333 4.09734 17.9 3.97267C17.9667 3.848 18 3.71034 18 3.55967C18 3.22634 17.8583 2.97634 17.575 2.80967C17.2917 2.643 17 2.65134 16.7 2.83467L10 7.03467L3.3 2.83467C3 2.65134 2.70834 2.64734 2.425 2.82267C2.14167 2.998 2 3.24367 2 3.55967C2 3.72634 2.03333 3.87234 2.1 3.99767C2.16667 4.123 2.26667 4.21867 2.4 4.28467L9.475 8.70967C9.55834 8.75967 9.646 8.79734 9.738 8.82267C9.83 8.848 9.91734 8.86034 10 8.85967Z" fill="white"/>
      </svg>
            </div>
      
            <div className="ml-4 mb-6">
              <h2 className="sendmail-text">Send us a mail</h2>
              <p className="mt-2 contact-link">hello@zibon.ng</p>
            </div>
          </div>
      
                 
                  </div>
      
                 
              </div>
      
              <div className="lg:mx-6 lg:w-1/2 pt-5">
                  <div className="mx-auto w-full overflow-hidden rounded-xl bg-white lg:px-8 lg:max-w-xl">
                  
                  <form 
                   ref={this.form}
                   name="contactform"
                   className="contactform"
                   onSubmit={this.contactSubmit.bind(this)}
                  >
                      <div className="flex-1">
                      <label className="mb-2 form-label">Your Name*</label>
                    
                      <input 
                                className="mt-2 block w-full rounded-md border border-gray-200 bg-white px-5 py-3 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                                id="grid-first-name"
                                name="lastname"
                                 type="text" 
                                 placeholder="" 
                                 onChange={this.handleChange.bind(this, "lastname")}
                                 value={this.state.fields["lastname"]}
                                 />
        <span style={{ color: "red" }}>{this.state.errors["lastname"]}</span>
                      </div>
      
                      <div className="mt-6 flex-1">
                      <label className="mb-2 form-label">Email Address*</label>
                      <input 
                      className="mt-2 block w-full rounded-md border border-gray-200 bg-white px-5 py-3 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      id="grid-first-name" 
                                type="email" 
                                name="email"
                                placeholder="" 
                                onChange={this.handleChange.bind(this, "email")}
                                 value={this.state.fields["email"]}
                                />
                                <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                     
                      </div>
      
                      <div className="mt-6 w-full">
                      <label className="mb-2 block form-label">How can we help you?</label>
                      <textarea 
                      className="mt-2 block h-32 w-full rounded-md border border-gray-200 bg-white px-5 py-3 text-gray-700 placeholder-gray-400 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 md:h-24" 
                      name="message"
                             
                               placeholder=""
                               onChange={this.handleChange.bind(this, "message")}
                                 value={this.state.fields["message"]}
                                 
                               ></textarea>
                               <span style={{ color: "red" }}>{this.state.errors["message"]}</span>
                      </div>
      
                      <button type="submit" className="mt-6 w-full transform rounded-md bg-[#F89A33] px-6 py-3 text-sm font-medium capitalize tracking-wide text-white transition-colors duration-300  focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-50">get in touch</button>
                  </form>
                  </div>
              </div>
              </div>
          </div>
          </div>
        </div>
    
                 
              </section>


     
    )
  }
}

export default ContactUs;

