import { useRef } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import NewsletterForm from './NewsletterForm';

const NewsletterSubscribe = () => {
  const myform = useRef();
  //const MAILCHIMP_URL = process.env.NEXT_PUBLIC_MAILCHIMP_URL;
  const MAILCHIMP_URL = "https://zibon.us21.list-manage.com/subscribe/post?u=b53197c9afa7a3061847ab505&amp;id=a2d5923042&amp;f_id=00e28ee1f0";
  //const MAILCHIMP_URL = "https://yahoo.us9.list-manage.com/subscribe/post?u=1ae3fdc5c7ada26cfae878125&amp;id=21993f873e&amp;f_id=00aa0ce1f0";
  return (
    <MailchimpSubscribe
      url={ MAILCHIMP_URL }
      render={ ( props ) => {
        const { subscribe, status, message} = props || {};
        return (
          <NewsletterForm
            formRef={myform}
            status={ status }
            message={ message }
            onSubmitted={ formData => {Promise.resolve(subscribe( formData ))}  }
          />
        );
      } }
    />
  );
};

export default NewsletterSubscribe;