//import logo from './logo.svg';
import '../index.css';
import Navigation from '../components/Navigation'

import Team from '../components/Team';
import About from '../components/About';
import WorkWithUs from '../components/WorkWithUs';
import Feature from '../components/Feature';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import ReactWhatsappButton from "react-whatsapp-button";

function AboutUs() {
    return (
      <div>
       
          
        <Navigation/>
        <About/>
        <Feature/>
        <Team/>
        <WorkWithUs/>
        <ContactUs></ContactUs>
        <ReactWhatsappButton
        countryCode="234"
        phoneNumber="8138346513"
        animated
      />
        <Footer/>
      </div>
    );
  }
  
  export default AboutUs;