/* This example requires Tailwind CSS v3.0+ */

import 'react-multi-carousel/lib/styles.css';



export default function HowItWorks() {

    return (
        <section className="bg-white">
            <div className="pt-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-16 lg:px-12">
       
       <h1 className="mb-4 text-4xl sm:text-5xl how-header">How it Works</h1>
      </div>
            <div className="flex">
                <div className="m-auto">
                    <div className="gap-16 py-8 px-4 mx-auto max-w-screen-xl sm:grid sm:grid-cols-2 sm:py-16 lg:px-8 lg:space-x-20 items-center lg:w-[80%]">
                        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                            <h2 className="mb-4 text-2xl sm:text-2xl step-header">Create an account</h2>
                            <p className="text-base sm:text-lg step-body">Sign up for an account with your name, email and phone number.</p>
                            <p className="mb-4 step-body"></p>

                        </div>
                        <div className="">
                            <img className="rounded-lg mx-auto" src="images/step1.png" alt="office content 1" />

                        </div>
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="m-auto">
                    <div className="col gap-16 py-4 px-4 mx-auto max-w-screen-xl sm:grid sm:grid-cols-2 lg:py-8 lg:px-8 lg:space-x-20 lg:w-[80%]">


                        <div className="">
                            <img className="rounded-lg mx-auto" src="images/step2.png" alt="office content 1" />

                        </div>
                        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                        <div className="ml-auto w-12 mb-10 sm:mb-20">
                        <img className="rounded-lg" src="images/arrow1.png" alt="office content 1" />
          </div>
                            <h2 className="mb-4 text-2xl sm:text-2xl step-header">Add location & list products</h2>
                            <p className="text-base sm:text-lg step-body">Add or confirm location and pick products to cart.</p>
                    
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="m-auto">
                    <div className="gap-16 py-8 px-4 mx-auto max-w-screen-xl sm:grid sm:grid-cols-2 lg:py-16 lg:px-8 lg:space-x-20 items-center lg:w-[80%]">
                        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                        <div className="mr-auto w-14 mb-10 sm:mb-20">
                        <img className="rounded-lg" src="images/arrow2.png" alt="office content 1" />
          </div>
                            <h2 className="mb-4 text-2xl sm:text-2xl step-header">Get connected to stores near you </h2>
                            <p className="text-base sm:text-lg step-body">Browse through stores that has the product you want.</p>
                            <p className="mb-4 step-body"></p>

                        </div>
                        <div className="">
                            <img className="rounded-lg" src="images/step3.png" alt="office content 1" />

                        </div>
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="m-auto">
                    <div className="col gap-16 py-8 px-4 mx-auto max-w-screen-xl sm:grid sm:grid-cols-2 lg:py-16 lg:px-8 lg:space-x-20 items-center lg:w-[80%]">


                        <div className="">
                            <img className="rounded-lg" src="images/step4.png" alt="office content 1"/>
                        </div>
                        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                        <div className="ml-auto w-12 mb-10 sm:mb-20">
                        <img className="rounded-lg" src="images/arrow3.png" alt="office content 1" />
          </div>
                            <h2 className="mb-4 text-2xl sm:text-2xl step-header">Guaranteed & prompt deliveries</h2>
                            <p className="text-base sm:text-lg step-body">Sit back, relax and get your product delivered to your doorstep.</p>
                            <p className="mb-4 step-body"></p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
