import { useEffect, useState } from 'react';
import { decode } from 'html-entities';

const NewsletterForm = ( { status, message, formRef,onSubmitted}) => {

  const [ error, setError ] = useState(null);
  const [ email, setEmail ] = useState(null);
  const myform = formRef;
  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setError(null);

    if ( ! email ) {
      setError('Please enter a valid email address');
      return null;
    }

    const isFormValidated = onSubmitted({ EMAIL: email });
    //console .log(email.indexOf("@"));
    // On success return true
    //
    return email && email.indexOf("@") > -1 && isFormValidated;
  }

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = ( event ) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  }

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if ( !message ) {
     return null;
    }
    const result = message?.split('-') ?? null;
    if ( "0" !== result?.[0]?.trim() ) {
     return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode( formattedMessage ) : null;
  }

  useEffect(() => {
    if (status === "success"){
      //const id = setTimeout(() => {
     myform.current.reset()
   // }, 5000);
    }
   
    // return () => {
    //     clearTimeout(id)
    // }
}, [myform,status])

  return (
    <section id="waitlist" className="bg-white">

    <div className="py-8 px-4 mx-auto max-w-screen-xl text-center justify-center lg:py-16 lg:px-12 md:w-[80%]">
        <p className="text-4xl sm:text-6xl subscribe-hero">Join the waitlist and <span className="text-4xl sm:text-6xl subscribe-sec">Get 25% off</span></p>
        <div className="px-4 lg:px-12">
                <img className="rounded-lg ml-auto" src="images/line1.png" alt="office content 1" />
  </div>
        <p className="sm:px-16 xl:px-48 subscribe-sub">Get updates about release date and new product on your email, no spam guaranteed
we promise.</p>
        <div className="flex w-full flex-col justify-center items-center">
        <div className="newsletter-form-info mt-10 mb-4">
        {status === "sending" && <div className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3">Sending...</div>}
        {status === "error" || error ? (
         
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert"
   dangerouslySetInnerHTML={{ __html: error || getMessage( message ) }}
/>
        ) : null }
        {status === "success" && status !== "error" && !error && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" dangerouslySetInnerHTML={{ __html: decode(message) }} />
        )}
      </div>
        <form 
        ref={formRef}
        className="flex w-full items-center justify-center">
       
        <div className="sm:border border-white flex-col sm:flex-row flex items-center lg:w-7/12 w-full  space-y-4 sm:space-y-0">
        
            <input 
            onChange={(event) => setEmail(event?.target?.value ?? '')}
            type="email"
            onKeyUp={(event) => handleInputKeyEvent(event)}
            className="w-full bg-[#D9D9D9] rounded-lg h-14 focus:border-[#F89A33] border-2 border-solid outline-none" 
            placeholder="Enter your email address..." 
            required="" />
            <button className="inline-flex items-center py-2.5 px-3 ml-2 primary-btn" onClick={handleFormSubmit}>

            Subscribe
        </button>
        </div>
       
        
    </form>
        </div>
        
    </div>
   
</section>
    
  );
}

export default NewsletterForm