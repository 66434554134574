/* This example requires Tailwind CSS v3.0+ */

export default function Privacy() {
  return (
    <section>
      <div>
        <div className="pt-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-10 lg:px-12">
          <h1 className="mb-4 text-4xl md:text-5xl terms-header">
            Privacy Policy
          </h1>
        </div>
        <div className="mx-auto pb-10 lg:w-[80%] px-10">
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <div className="md:12/12 lg:w-12/12">
              <p className="mt-6 story-body">
              This Privacy Policy between Zibon Online Ltd of Lagos, Nigeria (hereinafter referred to as Zibon)
and you, constitutes our adherence to your privacy on our administrative records, websites,
mobile applications, social media platforms and premises. This policy applies to our registered
buyers, vendors and those who purchase items on Zibon without an account and anyone else
who visits our Sites or uses our services.
              </p>
              <p className="story-body">
              By using our services, you accept this Privacy Policy when you give consent upon access to our
platforms, or use our services, content, features, technologies of functions offered on our
website, digital platforms or visit any of our offices for official or non—official purposes.
              </p>
              <h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">About us</h2>
              <p className="story-body">
              At Zibon, we are committed to safeguarding the privacy of the personal information that is
provided to us or collected by us during the course of our business as well as the personal
information we receive from visitors to our website. 
              </p>
              <p className="story-body">
              This Privacy policy explains how we may collect and use any personal information that we obtain
about you and your rights in relation to that information. It also sets out how to contact us. If you
have any questions about this Privacy policy or want to make a complaint to us about how we
handle your personal information.
              </p>
              <p className="story-body">If you have created a username, identification code, password or any other piece of information
as part of our access security measures, you must treat such information as confidential, and
you must not disclose it to any third party. We reserve the right to disable any user identification
code or password, whether chosen by you or allocated by us, at any time, if in our opinion you
have failed to comply with any of the provisions of these Conditions. If you know or suspect that
anyone other than you know your security details you must promptly notify us at <a href="mailto:hello@zibon.ng" className="contact-link">hello@zibon.ng</a>
</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">What information do we collect about you and how?</h2>
              <p className="story-body">We collect personal information about prospective users and their beneficial owners or
controllers as part of business development initiatives and our business acceptance process. We
receive and store any information you provide in relation to Zibon. We automatically collect and
store certain types of information about your use of Zibon, including information about your
interaction with products, content and services available to Zibon.</p>
              <p className="story-body">The type of personal information that we may collect includes current and historical information
including your name and contact details (such as your address, email address and telephone
numbers) and identifiers such as your organisation, employment history and positions held. We
obtain personal information from your IP address and the operating system and web browser that
you use to access our Websites. It enables us to identify which organisations have visited our
Websites and we use this information to compile statistical data on the use of those sites to help
us to improve the user experience.</p>
              <p className="story-body">We use a third party service provider to manage the company’s contact database and deliver
emails to inform you about our services, legal developments and updates and invite you to Zibon
events (including those we may jointly host with other organisations).</p>
              <p className="story-body">YYou can control the information you receive through our direct marketing function by using the
“Managing your preferences” option at the bottom of the emails. If you no longer wish to receive
marketing emails relating to our services by email or post, you can unsubscribe at any time by
using the “Unsubscribe” option on the email footer or by contacting <a href="mailto:hello@zibon.ng" className="contact-link">hello@zibon.ng</a></p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">How we use your information</h2>
              <p className="story-body">We will only use your personal information if and to the extent that applicable law allows. We will
therefore only process your personal information if:</p>
<ul className="list-disc">
                <li className="story-body">
                it is necessary for the performance of a contract with you or the organisation you work for;
                </li>
                <li className="story-body">
                it is necessary in connection with a legal obligation;
                </li>
                <li className="story-body">
                you have given your consent (where necessary) to such use or the organisation you work for
has obtained your consent (where necessary) to share your information with us; or
                </li>
                <li className="story-body">
                if we (or a third party) have a legitimate interest which is not overridden by your interests or
your rights and freedoms. Such legitimate interests include the provision of services, running the
company’s business and marketing relevant services directly to you.
                </li>
                
              </ul>
              <p className="story-body">We may use your personal information to:</p>
<ul className="list-disc">
                <li className="story-body">
                purchase and deliver products and services
                </li>
                <li className="story-body">
                consider whether we can pursue certain business development initiatives;
                </li>
                <li className="story-body">
                comply with our legal obligations to identify and verify the identity of our clients and their
beneficial owners;
                </li>
                <li className="story-body">
                deliver services to you and/or the organisation you work for, if you are a client;
                </li>
                <li className="story-body">
                run the firm’s business (e.g. carry out administrative or operational processes, including
recruitment);
                </li>
                <li className="story-body">
                recommend features, products and services that might be of interest to you
                </li>
                <li className="story-body">
                maintain and develop our business relationship with you;
                </li>
                <li className="story-body">
                improve our services and products to you, if you or the organisation you work for are a client or
prospective client;
                </li>
                <li className="story-body">
                identify services you may be interested in;
                </li>
                <li className="story-body">
                send you marketing and invite you to events;
                </li>
                <li className="story-body">
                monitor and analyse our business; or
                </li>

                <li className="story-body">
                process and respond to requests, enquiries or complaints received from you.
                </li>
                
              </ul>
              <p className="story-body">We will only retain your personal information for as long as is necessary for the purpose for
which it was collected, including for the purposes of complying with any legal, regulatory,
accounting or reporting requirements. Personal information processed in connection with our
business acceptance processes and/or providing legal services will be retained in accordance
with the firm’s Retention and destruction policy unless we agree otherwise with you, in writing. If
you wish to know more about the firm’s Retention and destruction policy or any of the firm’s
different retention periods, please contact <a href="mailto:hello@zibon.ng" className="contact-link">hello@zibon.ng</a></p>
              

<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">Cookies Policy</h2>
              <p className="story-body">Cookies are data files which can hold small amounts of info and they are stored on your device
(computer, smartphone, etc) when you first visit a website. We use cookies when you visit our
site, but you can choose to not allow some types of cookies. But don’t forget, blocking some
cookies may impact your experience of the site and the services we are able to offer.</p>
<h3 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">How do we use them?</h3>
<p className="story-body">We use cookies for:</p>
              <ul className="list-disc">
                <li className="story-body">
                essential operations, like site navigation
                </li>
                <li className="story-body">
                allowing you to add items to your shopping bag or to your Saved Items
                </li>
                <li className="story-body">
                analysing visitor numbers and behaviours, such as what pages are frequently visited
                </li>
                <li className="story-body">
                assessing the success of our advertising campaigns, offers and communications
                </li>
                <li className="story-body">
                targeting suitable advertising messages
                </li>
                <li className="story-body">
                understanding which Affiliates have helped us reach out to new customers, or have promoted
our products on their websites
                </li>
                
              </ul>
              <h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">How and why do we share your personal information?</h2>
              <p className="story-body">We may share your personal information with our offices and associated partnerships due to, for
example, our shared IT systems and/or cross jurisdictional working on a matter. We use third
parties who provide services on our behalf and will share your information with them, for example
a technology supplier may have access to your personal information when providing software
support, or a company we use for a communications campaign may process the personal
information of our contacts for us. We may also have to share your personal information with
regulators, government agencies, courts and other third parties.</p>
              <p className="story-body">We may only share your personal information with third parties where you have consented to us
doing so (where necessary) or the organisation that you work for has obtained your consent for
us to do so (where necessary) and we are under a legal, regulatory or professional obligation to
do so (for example, to comply with anti-money laundering or sanctions requirements).</p>

<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">Your rights</h2>
<p className="story-body">You have certain rights that you can exercise under certain circumstances in relation to the
personal information that we hold. These rights are to:</p>
              <ul className="list-disc">
                <li className="story-body">
                request access to your personal information (known as a subject access request) and request
certain information in relation to its processing;
                </li>
                <li className="story-body">
                request rectification of your personal information;
                </li>
                <li className="story-body">
                request the erasure of your personal information;
                </li>
                <li className="story-body">
                request that we restrict the processing of your personal information; and
                </li>
                <li className="story-body">
                object to the processing of your personal information.
                </li>
                
                
              </ul>

              <p className="story-body">In the limited circumstances where you may have provided your consent to the collection,
processing and transfer of your personal information for a specific purpose, you have the right to
withdraw your consent for that specific processing at any time. Once the company has received
notification that you have withdrawn your consent, we will no longer process your information for
the purpose or purposes you originally agreed to, unless we have another legitimate basis for
doing so in law.</p>       
<p className="story-body">If you would like to exercise any of these rights, please contact us by emailing <a href="mailto:hello@zibon.ng" className="contact-link">hello@zibon.ng</a></p>


              <p className="mt-4 text-gray-600"></p>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
