/* This example requires Tailwind CSS v3.0+ */



export default function WorkWithUs() {

    return (
        <section className='bg-[url("../public/images/bg.svg")]'>

<div className="w-full  shadow-xl shadow-indigo-200 px-10 py-20 lg:px-20 lg:flex justify-between">
            <p className="text-center lg:text-left text-white"> <span className="md:mb-4 text-4xl md:text-5xl wwu-header pb-4">Come work with us</span> <br/> <span className="pb-4 wwu-subtext">Come work with people interested in your growth </span></p>
            <div className="flex items-end">
                            <img className="rounded-lg hidden lg:block object-bottom" src="images/arrowwork.png" alt="office content 1" />

                        </div>
                        <div className="flex text-center lg:text-left justify-center mt-10 md:mt-0 align-center items-end">
                        <a href="/#emailus" className="px-5 primary-btn">EMAIL US </a>

                        </div>
           
        </div>
           
        </section>
    )
}
