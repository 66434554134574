//import logo from './logo.svg';
import '../index.css';
import Navigation from '../components/Navigation';
import ContactPage from '../components/ContactPage';
import Footer from '../components/Footer';
import ReactWhatsappButton from "react-whatsapp-button";

function Contact() {
    return (
      <div className='bg-[#FBFBFB]'>
        <Navigation/>

        <ContactPage/>
        <Footer/>
        <ReactWhatsappButton
        countryCode="234"
        phoneNumber="8138346513"
        animated
      />
      </div>
    );
  }
  
  export default Contact;