/* This example requires Tailwind CSS v3.0+ */
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { HashLink } from 'react-router-hash-link';


const navigation = [
    { name: 'Home', href: "/", active: true },
    { name: 'About Us', href: "/about-us", active: false },
    { name: 'Contact Us', href: "/contact-us", active: false },
]

export default function Navigation() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        
         
            <div className='display:inline-grid  grid-cols-8 gap-4'>

            <div className="flex items-center justify-center px-6 pt-1 lg:px-8 header">
                <div className='topbar lg:w-[70%] w-full lg:shadow-lg'>
                    <nav className="flex mb-1 space-x-6 items-center justify-between h-70" aria-label="Global">
                        <div className="flex lg:w-1/5 lg:justify-end" aria-label="Global">
                            <a href="/" className="block sm:hidden m-1.5 p-1.5">
                                
                                <img alt="logo" className='logos' src="images/zibon.png" />
                            </a>
                            <a href="/" className="hidden sm:block m-1.5 p-1.5">
                    
                                <img alt="logo" className='logol' src="images/zibon.png" />
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-full px-5 p-2.5 text-gray-700 menu-button"
                                onClick={() => setMobileMenuOpen(true)}
                            ><span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="hidden lg:flex w-2/5 lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-16 white-space: nowrap">
                            {navigation.map((item) => (
                                <a key={item.name} href={item.href} className="font-semibold text-gray-900 text-transform:uppercase hover:text-gray-900 white-space: nowrap">
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <div className="hidden lg:flex w-/5 lg:min-w-0 lg:flex-2 lg:justify-end space-x-12 px-5">
                           
                            <HashLink  to="/#waitlist" className="inline-block rounded-lg px-3 py-1.5 text-sm font-bold leading-6 sign-up">
                            Join Waitlist
                            </HashLink>
                            
                        </div>
                    </nav>
                    <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
                            <div className="flex h-9 items-center justify-between">
                                <div className="flex">
                                    <a href="/" className="-m-1.5 p-1.5">
                                        <span className="sr-only">Zibon</span>
                                        <img
                                            className="h-12"
                                            src="/logo.png"
                                            alt="logo"
                                        />
                                    </a>
                                </div>
                                <div className="flex">
                                    <button
                                        type="button"
                                        className="-m-2.5 inline-flex items-center justify-center rounded-full menu-button p-2.5 text-gray-700"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="py-6">
                                        
                                    <HashLink smooth to="/#waitlist" className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10">
                                        Join Waitlist
                                    </HashLink>   
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </div>
            </div>
            </div>
            
  
    )
}
