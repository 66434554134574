/* This example requires Tailwind CSS v3.0+ */

export default function Terms() {
  return (
    <section>
      <div>
        <div className="pt-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-10 lg:px-12">
          <h1 className="mb-4 text-4xl md:text-5xl terms-header">
            TERMS AND CONDITIONS
          </h1>
        </div>
        <div className="mx-auto pb-10 lg:w-[80%] px-10">
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <div className="md:12/12 lg:w-12/12">
              <p className="mt-6 story-body">
              Zibon Online Limited is a company registered in
                Nigeria with registered address at Portharcourt, Rivers state
                and registered under the Companies and Allied Matters Act (CAMA)
                Zibon Online Limited has proprietary rights over the
                website and mobile application. The sole purpose of the company
                is the provision of a social commerce platform for buyers and
                sellers which include but not limited to sale and purchase of
                products, facilitation of payment services and logistics
                services. 
              </p>
              <p className="story-body">
                For the purpose of this agreement, Zibon Online Limited, her website and mobile application will be individually
                and collectively referred to as “Zibon, We, Our, Us” and the
                buyer, seller or User of the website or mobile application will
                be referred to as “You or Your or User(s)” while the website or
                mobile application will be referred to as “Site”. 
              </p>
              <p className="story-body">
                The Terms and Conditions hereinafter referred to as “Terms”
                shall serve as a binding legal agreement between Zibon and You.
                By using this Site, you agree to be bound by the Terms stated
                below. If you do not agree with any provision of the Terms, or
                if you are not eligible or authorized to be bound by these
                Terms, then do not access or use the Site. You are only allowed
                to use this site if you are juristic person (a person with the
                capability to sue and be sued). For the purpose of
                clarification, we are not agents or parties to any form of
                transaction on the site. 
              </p>
              <p className="story-body">
                Any guidance Zibon provides as part of our services such as
                pricing, shipping, listing and sourcing is solely information
                which you may decide to follow it or not. Unless otherwise
                expressly provided, Zibon has no control over and does not
                guarantee: the existence, quality, safety or legality of items
                advertised; the truth or accuracy of users’ content or listing;
                the ability of sellers to sell items; the ability of buyers to
                pay for items; or that a buyer or seller will actually complete
                a transaction or return an item.
              </p>
              <p className="story-body">We hope you go through the Terms in a meticulous manner to understand the obligations
between Us and You.You can also find out more on our HELP pages which we update regularly for our users (The Help
page serves as part of our terms)
</p>
              <p className="story-body">We have the right to amend, remove or vary our services and/or any part of the Site (including
our terms) at any time without notification to the user.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">MEMBERSHIP ELIGIBILITY AND CONFIDENTIALITY</h2>
              <p className="story-body">By use of this Site, you agree that you are not below the age of 18 and you are capable of
entering a contract. If you are under the age of 18, you are not allowed to use this site. A parent
or legal guardian will be authorized under the Terms to open an account for anyone under the
age of 18 with appropriate supervision. A parent or guardian is responsible for a minor (anyone
under the age of 18). There may be commercial products or services available that you may want
to consider to limit a minor’s access to material online.</p>
              <p className="story-body">You agree to provide us with accurate information when opening an account on the site. Your
account name should not consist of offensive language. Any false or misleading information
provided by you will lead to either suspension or termination
of your account from this site. You are not allowed to impersonate another person or company
through your account.</p>
              <p className="story-body">You can register only one account. If more than one person accesses an account linked to you
from the same device, we may request to upload the proof of identity to avoid duplicity of
accounts.</p>
              <p className="story-body">You agree to provide a credit or debit card for the purpose of payment for products and services
made available on the site.</p>
              <p className="story-body">You must be authorised to use that credit or debit card on our site</p>
              <p className="story-body">It is your duty to ensure the confidentiality of your account details. You are not allowed to use
another account on the site without necessary consent and permission.</p>
              <p className="story-body">You are advised to notify us immediately via our verified channels if you become aware of any
disclosure of your password.</p>
              <p className="story-body">Your account shall be used exclusively by you and you shall not transfer your account to any
third party. If you authorize any third party to manage your account on your behalf this shall be at
your own risk.</p>
              <p className="story-body">Zibon reserves the right to refuse service, terminate accounts, terminate your rights to use our
Site, remove or edit content, or cancel orders in our sole discretion.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">PRICES & CHARGES</h2>
              <p className="story-body">We are not under any obligation to notify you of any increase in charges. It is your duty to
confirm the price of products and services before proceeding to payment.</p>
              <p className="story-body">The sum-total of a product or service purchased on our site will include the amount for the
product or service, the cost of processing the transaction, the Value Added tax (VAT), the cost of
delivery, and the cost of processing the transaction will be on display when viewed on the
checkout cart.</p>
<div className="pt-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-2 lg:px-2">
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">DISCLAIMER</h2>
        </div>

<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">LIMITATION OF LIABILITY</h2>
              <p className="story-body">WE OR ANY OF OUR EMPLOYEES, AGENTS, DIRECTORS, SHAREHOLDERS SHALL NOT BE HELD
LIABLE IN RESPECT OF:</p>
              <ul className="list-disc">
                <li className="story-body">
                USE OF THIS SITE BY YOU, CONTENT DISPLAYED ON THE SITE, PRODUCTS DISPLAYED ON
THE SITE, DISCLOSURE OF YOUR INFORMATION
                </li>
                <li className="story-body">
                DAMAGES THAT MAY RESULT FROM PURCHASE OF A PRODUCT FROM THE SITE, DAMAGES
THAT MAY OCCUR FROM USE OF PRODUCT PURCHASED FROM THE SITE, DELAY IN
OPERATION OF THE SITE, VIRUSES ON THE SITE SYSTEM WHETHER OR NOT RESULTING FROM
CYBER ATTACKS, UNAUTHORIZED ACCESS TO SECURITY STRUCTURE OF THE SITE, ACTS OF
GOD
                </li>
                <li className="story-body">
                WE ARE NOT LIABLE FOR ANY WARRANTY ON PRODUCT, THE SELLER SHALL BE LIABLE FOR
ANY FORM OF WARRANTY.
                </li>
                <li className="story-body">
                WE ARE NOT RESPONSIBLE FOR ANY CONTENT POSTED BY A USER ON THE SITE.
                </li>
                <li className="story-body">
                THE USE OF PRODUCTS AND SERVICES ARE SOLELY AT THE RISK OF THE USER.
                </li>
                <li className="story-body">
                WE ARE NOT LIABLE FOR ANY LOSS OF PROFIT THAT MAY OCCUR FROM THE
USE OF THE SITE.
                </li>
                <li className="story-body">
                YOU ARE ENCOURAGED TO CHECK THE GOODS BEFORE PAYMENT AND TO REQUEST THE
SELLER TO PROVIDE DOCUMENTS CONFIRMING COMPLIANCE OF THE GOODS WITH
APPLICABLE REQUIREMENTS OF LAWS, REGULATIONS, RULES, GUIDELINES, STANDARDS.
                </li>
                <li className="story-body">
                YOU ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR YOUR SAFETY AND YOU
UNDERSTAND THAT SHOULD YOU MEET WITH OTHER INDIVIDUALS IN RESPECT OF ANY
TRANSACTION YOU ARE SOLELY RESPONSIBLE FOR YOUR SAFETY.
                </li>
                <li className="story-body">
                YOU RELEASE US FROM ANY CLAIM RELATED TO PRODUCTS AND SERVICES PURCHASED
ON OUR SITE INCLUDING BUT NOT LIMITED TO DEFECTIVE GOODS, MISREPRESENTATIONS BY
SELLERS, OR PRODUCTS THAT MAY BE CONSIDERED AS DANGEROUS OR HARMFUL.
                </li>
                <li className="story-body">
                WE ARE NOT LIABLE FOR ANY ISSUE THAT ARISES FROM ANY FORM OF INTERACTION
BETWEEN USERS OF THIS SITE.
                </li>
              </ul>
              <h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">PRODUCT AND SERVICE INFORMATION</h2>
              <p className="story-body">We take considerable effort to ensure the accuracy of information on products and services
displayed on our site. That being said, the information on any product or service may be
inaccurate. We will not be liable for the inaccuracy of any information.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">PRODUCT AND SERVICE INFORMATION</h2>
              <p className="story-body">We take considerable effort to ensure the accuracy of information on products and services
displayed on our site. That being said, the information on any product or service may be
inaccurate. We will not be liable for the inaccuracy of any information.</p>

<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">PLACING ORDERS</h2>
<p className="story-body">When placing orders for any product or service in this site, you will be required to provide us with
a valid email address to confirm your order, a credit or debit card which we are allowed to
conduct a background check on the card for the purpose of payment of the product or service.</p>
<p className="story-body">Our background check on the card is to ensure that there is no unusual activity surrounding the
use of the card. Any unusual activity surrounding the use of the card will lead to either
suspension or termination of the account.</p>
<p className="story-body">We will only accept your order when payment has been approved and we have debited the
payment card.</p>
<p className="story-body">An order when placed by the user can only be canceled within 12 hours of the deduction of funds
from the user’s account.</p>
<p className="story-body">An order can only be approved on the site if the product is available.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">DELIVERY</h2>
<p className="story-body">Before you finalize your order, you will be given various delivery options to choose from with
estimated delivery time and dates depending on the delivery address.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">RETURN & REFUND POLICY</h2>
<ul className="list-disc">
                <li className="story-body">
                When you purchase a product from our site, for reasons best known to you, you may want to
return the product and ask for a refund. For us to refund your money to you, a product must be
returned within a period of 10 days after delivery of the product to you. There will be no refund
for any product that is delivered after a period of 10 days. In addition to this policy, refund will
only occur after the product has been inspected by the vendor or seller and notification to us of
the merchantable quality and proper condition of the product. There will be no refund if the
vendor or seller does not notify us of the merchantable quality and proper condition of the
product. A refund will occur within a period of 28 days. If there is no refund of money within the
stated time frame, you are required to send a mail to the complaint mail : <a href="mailto:hello@zibon.ng" className="contact-link">hello@zibon.ng</a>
                </li>
                <li className="story-body">
                There will be no return and refund for sensitive and hygienic products.
                </li>
                <li className="story-body">
                If upon investigation, we find out that you always return products we will
either suspend or terminate your account.
                </li>
                
              </ul>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">YOUR CONTENT</h2>
<p className="story-body">Content that you post using our site is your content. We do not make any claim to it, which
includes anything you post using our site.</p>
<p className="story-body">By use of this site, you understand that you are solely responsible for your content. You
represent that you have all necessary rights to all parts of your content and that you are not
infringing or violating any third party’s rights by posting it.</p>
<p className="story-body">By use of this site, you grant us the right and authorization to use your content (for the purpose
of clarification under the terms, content includes but is not limited to text, graphics, visual interfaces, photos, trademarks, logos, sounds, music, artwork) for the
purpose of promotion and advertisement of our site.</p>
<p className="story-body">If there is content that you own or have rights that have been posted to the site without your
permission and you want it removed, reach out to our verified channels with appropriate
information. If your content is alleged to infringe another person’s intellectual property, we will
take appropriate action, such as suspending and/or terminating your account.</p>
<p className="story-body">You agree that you will not post any content that is abusive, threatening, defamatory, obscene,
vulgar or otherwise offensive. You also agree not to post any content that is false and misleading
or uses the Site in a manner that is fraudulent or deceptive.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">GENERAL USE OF THE SITE</h2>
<p className="story-body">Subject to the provisions outlined under the terms of this site, there are subsequent conditions
attached to the use of the site:</p>
<ul className="list-disc">
                <li className="story-body">
                You shall not display false or misleading information on any product or service.
                </li>
                <li className="story-body">
                The content displayed by you on this site shall not be harmful, indecent, abusive, threatening,
pornographic, discriminatory, racially abusive, religiously abusive, fraudulent in nature.
                </li>
                <li className="story-body">
                You shall not engage in any activity that threatens the cybersecurity of the site.
                </li>
                <li className="story-body">
                You agree not to reverse engineer or obtain the source code of this site.
                </li>
                <li className="story-body">
                You shall not engage in any activity that goes against the governing law under
the Terms.
                </li>
                <li className="story-body">
                You shall not engage in any illegal activity during the use of this site.
                </li>
                <li className="story-body">
                You shall not engage in illegal sharing of copyrighted work. Any use of
copyrighted work must be by authorization from the owner of the work.
                </li>
                <li className="story-body">
                You shall not infringe on any of our patent, trademark, copyright or other
proprietary rights.
                </li>
                <li className="story-body">
                You shall not engage in any activity that seeks to test the vulnerability of the
site.
                </li>
                <li className="story-body">
                You must not misuse or tamper with our site through various means which
may include but not limited to hacks, viruses, Trojans, worms and other
technologically harmful material.
                </li>
                <li className="story-body">
                You must not interfere with the functioning of our services, such as by
imposing an unreasonable or disproportionately large load on our
infrastructure.
                </li>
                <li className="story-body">
                You must not infringe or steal the data of our users.
                </li>
                <li className="story-body">
                We recommend you use virus protection software when using our site.
                </li>
                <li className="story-body">
                You are not allowed to use automated systems, software, algorithm or mechanisms (which
include but not limited to spiders, bots, crawlers, avatars) to collect data or acquire any portion
from the site.
                </li>
                <li className="story-body">
                No form of impersonation is allowed on the site.
                </li>
                <li className="story-body">
                You shall not strain the infrastructure of our site by uploading large forms of
data.
                </li>
              </ul>
              <h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">INTELLECTUAL PROTECTION/TRADEMARK PROTECTION</h2>
<p className="story-body">The site is a trademark that belongs to us. The Intellectual Property rights in all software relating
to the use of this site is our sole property and the rights to this property are protected by
copyright laws and international regulations around the world. We control proprietary rights over
any registered trademarks under this site.</p>
<p className="story-body">You are not allowed to use any logo or brand associated with us without our express consent.</p>
<p className="story-body">You are not allowed to modify, copy, store, reproduce, print, republish, upload, post, distribute or
use software relating to the site for personal or commercial use without our express consent.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">EXTERNAL LINKS AND AFFILIATES</h2>
<p className="story-body">For efficient use of our site, there may be links to third party websites and applications. The third
party websites and applications are not subject to our control and we bear no responsibility
whatsoever relating to these external links.</p>
<p className="story-body">We are not associated with any third party or affiliates; if we intend to engage in any affiliation
with any third party, a notification will be sent to you via our official channels.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">TERMINATION</h2>
<p className="story-body">You may terminate your account with Zibon at any time from your account settings.</p>
<p className="story-body">We may terminate or suspend your account on breach of our policies and regulations. On
termination of your account, you do not have a contractual or legal right to continue to use or
Site for any purpose. Zibon will notify you through verified channels that your account has been
terminated or suspended.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">DISCLOSURE</h2>
<p className="story-body">Although we have stated that your information is confidential, we may need to disclose your
information for the purpose of investigations or conduct of legal actions against you.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">MODIFICATIONS</h2>
<p className="story-body">It is our duty to make modifications, changes and updates to the Terms to provide more
information and assistance when needed. We will not notify you when changes are made to this
site and the terms; it is your duty to check the Terms in the site for subsequent changes to the
terms. Your continuous use of the site implies that you accept the modified terms.</p>

<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">COMPLAINTS</h2>
<p className="story-body">We hope that in the use of this site, there will not be complaints, but in any case any grievance or
dissatisfaction arising from the use of the site should be forwarded to <a href="mailto:hello@zibon.ng" className="contact-link">hello@zibon.ng</a></p>
<p className="story-body">It should be noted that a complaint must include a comprehensive detail of any facts or evidence
that you consider relevant to your complaint in support of your case. Once you have submitted
your complaint, we have an obligation to process claims within 90 days of receiving evidence.</p>

<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">GOVERNING LAW</h2>
<p className="story-body">The applicable law shall be the laws of the Federal Republic of Nigeria.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">DISPUTE RESOLUTION CLAUSE</h2>
<p className="story-body">We hope that no dispute will arise from the use of our site but in the case of any dispute, the
governing law under the Terms shall be applied between you and us. You agree to resolve all
disputes through arbitration, which means that you waive the right to have disputes decided by a
Court. You waive your right to participate in class actions, class arbitrations or representative
actions. Any dispute or claim arising from the use of this site must be filed in Abuja/Lagos
(choose whichever is convenient), Nigeria. Any cost arising from resolving disputes arising
between you and us will be borne solely by you.</p>
<h2 class="pt-3 md:mt-4 text-2xl md:text-3xl terms-header">BREACH</h2>
<p className="story-body">In the event that there is any breach or violation of the Terms, we have the exclusive right to
suspend your account or terminate your account without notifying you. We also have the right to
block your IP address from further accessing our site. We may also conduct legal proceedings
against you for breach of the terms.</p>

              <p className="mt-4 text-gray-600"></p>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
