
import { HashLink } from 'react-router-hash-link';

export default function Platforms() {

    return (
        <section className="bg-white lg:px-20">
         <div className="flex items-center justify-center p-6 py-6">
  <div className="mx-auto w-full bg-[#204D78] px-4 py-10 sm:px-16 rounded-2xl">
    <div className="col grid md:grid-cols-3 ">

      <div className="flex col-span-2 sm:col-span-2 items-center">
        <div className="text-center sm:text-left mx-auto md:mx-0">
          <h3 className="text-4xl sm:text-5xl platform-text pb-10">Available soon<br/> on each platform</h3>

          <HashLink  to="/#waitlist"   className="primary-btn inline-flex justify-center items-center text-base text-center rounded-lg">
                Join Waitlist
            </HashLink>  
        </div>
      </div>
      <div className="flex col-span-2 sm:col-span-1 sm:justify-center md:justify-end ">
        <img className="w-full max-w-md sm:max-w-sm" src="images/platform.png" alt="PNG, SVG" />
      </div>
    </div>
  </div>
</div>
        </section>
    )
}
