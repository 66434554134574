//import logo from './logo.svg';
import '../index.css';
import Navigation from '../components/Navigation'

import Term from '../components/Terms';

import Footer from '../components/Footer';


function Terms() {
    return (
      <div>
       
          
        <Navigation/>
        
        <Term/>
        
        <Footer/>
      </div>
    );
  }
  
  export default Terms;