//import logo from './logo.svg';
import '../index.css';
import Hero from '../components/Hero'
import WhoWeAre from '../components/WhoWeAre';
import HowItWorks from '../components/HowItWorks';
import Platforms from '../components/Platforms';
import Faq from '../components/Faq';

import Footer from '../components/Footer';
import NewsletterSubscribe from '../components/NewsletterSubscribe';
import ReactWhatsappButton from "react-whatsapp-button";

function Home() {
    return (
      <div>
        <Hero>
          
        </Hero>
        
        <WhoWeAre></WhoWeAre>
        <HowItWorks/>
        <Platforms/>
        <Faq/>
       
       <NewsletterSubscribe/>
        <Footer/>
        <ReactWhatsappButton
        countryCode="234"
        phoneNumber="8138346513"
        animated
      />
      </div>
    );
  }
  
  export default Home;