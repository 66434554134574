//import logo from './logo.svg';
import '../index.css';
import Navigation from '../components/Navigation'

import Privacy from '../components/Privacy';

import Footer from '../components/Footer';


function Policy() {
    return (
      <div>
       
          
        <Navigation/>
        
        <Privacy/>
        
        <Footer/>
      </div>
    );
  }
  
  export default Policy;