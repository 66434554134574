/* This example requires Tailwind CSS v3.0+ */
import { useRef, useState, useEffect } from "react"
import { Popover } from "@headlessui/react"


export default function Team() {

  let timeout // NodeJS.Timeout
  const timeoutDuration = 400

  const buttonRef = useRef(null) // useRef<HTMLButtonElement>(null)
  const [openState, setOpenState] = useState(false)

  const toggleMenu = (open,button) => {
    // log the current open state in React (toggle open state)
    setOpenState((openState) => !openState)
    // toggle the menu by clicking on buttonRef
    console.log(open)
    button?.current?.click() // eslint-disable-line
  }

  // Open the menu after a delay of timeoutDuration
  const onHover = (open, action,button) => {
    // if the modal is currently closed, we need to open it
    // OR
    // if the modal is currently open, we need to close it
    if (
      (!open && action === "onMouseEnter") ||
      (open  && action === "onMouseLeave")
    ) {
      console.log(action,button.current)
      // clear the old timeout, if any
      //clearTimeout(timeout)
      // open the modal after a timeout
      toggleMenu(open,button)
    }
    // else: don't click! 😁
  }

  const handleClick = (open) => {
    setOpenState(!open) // toggle open state in React state
    clearTimeout(timeout) // stop the hover timer if it's running
  }

  
  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      event.stopPropagation()
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
    return (
        <section className="bg-white">
           <div className="2xl:container 2xl:mx-auto md:py-12 lg:px-20 md:px-6 py-9 px-4">
  
  <div className="text-center">
  <h1 className="md:mb-4 text-4xl md:text-5xl story-header">Meet the Team</h1>

  <p className="pb-8 text-lg lg:text-xl sm:px-16 xl:px-48 team-subtitle">We’re a diverse team focused on building an app that can improve the lives of Africans while contributing to the economy.</p>
        
  </div>
  <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:grap-8 md:gap-6 gap-4 mt-10">
  
  <div className="relative max-w-xs mx-auto">
  <Popover>
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of the chevron icon. */
        <>
          <Popover.Button className={"w-full outline-none"}
          onClick={() => handleClick(open)}
          >
          <div 
          className={open ? 'w-full h-full bg-[#F89A33] absolute' : 'w-full h-full absolute'}
          onMouseEnter={(action) => onHover(open, "onMouseEnter",action)}
            onMouseLeave={() => onHover(open, "onMouseLeave")}
          >
                            
                        </div>
                        <div className="relative right-4 bottom-4">
                            <img className="w-full h-full relative"
                                src="images/team1.png" alt=""/>

                        </div>
                        
      <Popover.Panel className="w-full">
     
      <div 
          className={open ? 'absolute right-4 bottom-4 w-full h-full flex justify-center items-center bg-black/30' : 'absolute top-0 left-0 w-full flex justify-center items-center opacity-0'}
          >
            

            <div className="text-center text-gray-500 dark:text-gray-400">
              
              <span className="mb-1 team-name">
                  Victor Bamikole
              </span>
              <p className="team-sub">Technical</p>
              <ul className="flex justify-center mt-4 space-x-4">
                  <li className='rounded-full'>
                  <a href="#/" className="text-[#204D78] hover:text-[#F89A33]">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21575 23.7843 0.5 15.5 0.5C7.21575 0.5 0.5 7.21575 0.5 15.5C0.5 23.7843 7.21575 30.5 15.5 30.5Z" fill="currentcolor"/>
<path d="M20 12.1809H16.5944V10.5203C16.5944 9.89663 17.1503 9.75123 17.5419 9.75123C17.9326 9.75123 19.9452 9.75123 19.9452 9.75123V7.0096L16.6353 7C12.9611 7 12.1249 9.04479 12.1249 10.3533V12.1809H10V15.006H12.1249C12.1249 18.6315 12.1249 23 12.1249 23H16.5944C16.5944 23 16.5944 18.5885 16.5944 15.006H19.6102L20 12.1809Z" fill="#F9F9F9"/>
</svg>
                    </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="#/" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 30.5001C23.7843 30.5001 30.5 23.7843 30.5 15.5C30.5 7.21574 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21574 0.5 15.5C0.5 23.7843 7.21573 30.5001 15.5 30.5001Z" fill="currentcolor"/>
<path d="M22.5 11.6597C21.9758 11.892 21.4196 12.0442 20.8502 12.1114C21.4501 11.7527 21.8991 11.188 22.1134 10.5227C21.5495 10.8572 20.9327 11.0929 20.2894 11.2195C20.0206 10.9328 19.6957 10.7044 19.335 10.5484C18.9743 10.3924 18.5853 10.3122 18.1923 10.3127C16.6064 10.3127 15.3201 11.5991 15.3201 13.185C15.3201 13.4102 15.3456 13.6293 15.3948 13.8397C13.0077 13.7199 10.8912 12.5765 9.47442 10.8382C9.21894 11.2766 9.08468 11.775 9.08542 12.2824C9.08542 13.2786 9.59287 14.1582 10.3632 14.6731C9.907 14.6591 9.46089 14.5357 9.06229 14.3135L9.06207 14.3499C9.06207 15.7416 10.0526 16.9024 11.3663 17.1661C11.1197 17.2333 10.8653 17.2672 10.6097 17.2669C10.4242 17.2669 10.2445 17.2492 10.0693 17.2158C10.4347 18.3566 11.4952 19.1871 12.7524 19.2104C11.7349 20.009 10.4783 20.4421 9.18481 20.44C8.95347 20.44 8.72427 20.4265 8.5 20.3997C9.81306 21.2439 11.3415 21.692 12.9026 21.6903C18.1857 21.6903 21.0749 17.3136 21.0749 13.5177C21.0749 13.3932 21.0721 13.2692 21.0665 13.1463C21.6291 12.7401 22.1145 12.2366 22.5 11.6597Z" fill="#F9F9F9"/>
</svg>
                      </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="https://www.linkedin.com/in/victor-bamikole-28a642181/" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_488_42)">
<path d="M0.5 5.5C0.5 2.73857 2.73492 0.5 5.49183 0.5H24.8832C27.6401 0.5 29.875 2.73857 29.875 5.5V25.5C29.875 28.2614 27.6401 30.5 24.8832 30.5H5.49183C2.73492 30.5 0.5 28.2614 0.5 25.5V5.5Z" fill="currentcolor"/>
<path d="M20.5608 21.9662H23.5V16.0287C23.5 13.0963 21.7277 12.0961 20.0877 12.0961C18.5711 12.0961 17.5409 13.1051 17.2565 13.6961V12.3659H14.4298V21.9662H17.369V16.7613C17.369 15.3734 18.224 14.6985 19.0961 14.6985C19.921 14.6985 20.5608 15.1756 20.5608 16.7227V21.9662ZM9.5 9.29365C9.5 10.2959 10.2587 11.0284 11.1945 11.0284C12.1305 11.0284 12.8892 10.2959 12.8892 9.29365C12.8892 8.2915 12.1305 7.55823 11.1945 7.55823C10.2587 7.55823 9.5 8.2915 9.5 9.29365ZM9.72497 21.9588H12.664V12.3585H9.72497V21.9588Z" fill="#F9F9F9"/>
</g>
<defs>
<clipPath id="clip0_488_42">
<rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>
                      </a>
                  </li>
                 
              </ul>
          </div>

      </div>
   
      
          
 
          </Popover.Panel>
          
          </Popover.Button>
          
        </>
      )}
    </Popover>
                        

</div>

<div className="relative max-w-xs mx-auto">
  <Popover>
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of the chevron icon. */
        <>
          <Popover.Button className={"w-full outline-none"}
          onClick={() => handleClick(open)}
          >
          <div 
          className={open ? 'w-full h-full bg-[#F89A33] absolute' : 'w-full h-full absolute'}
          onMouseEnter={(action) => onHover(open, "onMouseEnter",action)}
          onMouseLeave={() => onHover(open, "onMouseLeave")}
          >
                            
                        </div>
                        <div className="relative right-4 bottom-4">
                            <img className="w-full h-full relative"
                                src="images/team2.png" alt=""/>

                        </div>
                        
      <Popover.Panel className="w-full">
     
      <div 
          className={open ? 'absolute right-4 bottom-4 w-full h-full flex justify-center items-center bg-black/30' : 'absolute top-0 left-0 w-full flex justify-center items-center opacity-0'}
          >
            <div className="text-center text-gray-500 dark:text-gray-400">
              
              <span className="mb-1 team-name">
                  Kayode Mustapha
              </span>
              <p className="team-sub">Graphics Designer</p>
              <ul className="flex justify-center mt-4 space-x-4">
                  <li className='rounded-full'>
                  <a href="#/" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21575 23.7843 0.5 15.5 0.5C7.21575 0.5 0.5 7.21575 0.5 15.5C0.5 23.7843 7.21575 30.5 15.5 30.5Z" fill="currentcolor"/>
                    <path d="M20 12.1809H16.5944V10.5203C16.5944 9.89663 17.1503 9.75123 17.5419 9.75123C17.9326 9.75123 19.9452 9.75123 19.9452 9.75123V7.0096L16.6353 7C12.9611 7 12.1249 9.04479 12.1249 10.3533V12.1809H10V15.006H12.1249C12.1249 18.6315 12.1249 23 12.1249 23H16.5944C16.5944 23 16.5944 18.5885 16.5944 15.006H19.6102L20 12.1809Z" fill="#F9F9F9"/>
                    </svg>
                    </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="#/" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 30.5001C23.7843 30.5001 30.5 23.7843 30.5 15.5C30.5 7.21574 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21574 0.5 15.5C0.5 23.7843 7.21573 30.5001 15.5 30.5001Z" fill="currentcolor"/>
<path d="M22.5 11.6597C21.9758 11.892 21.4196 12.0442 20.8502 12.1114C21.4501 11.7527 21.8991 11.188 22.1134 10.5227C21.5495 10.8572 20.9327 11.0929 20.2894 11.2195C20.0206 10.9328 19.6957 10.7044 19.335 10.5484C18.9743 10.3924 18.5853 10.3122 18.1923 10.3127C16.6064 10.3127 15.3201 11.5991 15.3201 13.185C15.3201 13.4102 15.3456 13.6293 15.3948 13.8397C13.0077 13.7199 10.8912 12.5765 9.47442 10.8382C9.21894 11.2766 9.08468 11.775 9.08542 12.2824C9.08542 13.2786 9.59287 14.1582 10.3632 14.6731C9.907 14.6591 9.46089 14.5357 9.06229 14.3135L9.06207 14.3499C9.06207 15.7416 10.0526 16.9024 11.3663 17.1661C11.1197 17.2333 10.8653 17.2672 10.6097 17.2669C10.4242 17.2669 10.2445 17.2492 10.0693 17.2158C10.4347 18.3566 11.4952 19.1871 12.7524 19.2104C11.7349 20.009 10.4783 20.4421 9.18481 20.44C8.95347 20.44 8.72427 20.4265 8.5 20.3997C9.81306 21.2439 11.3415 21.692 12.9026 21.6903C18.1857 21.6903 21.0749 17.3136 21.0749 13.5177C21.0749 13.3932 21.0721 13.2692 21.0665 13.1463C21.6291 12.7401 22.1145 12.2366 22.5 11.6597Z" fill="#F9F9F9"/>
</svg>
                      </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="https://www.linkedin.com/in/victor-bamikole-28a642181" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_488_42)">
<path d="M0.5 5.5C0.5 2.73857 2.73492 0.5 5.49183 0.5H24.8832C27.6401 0.5 29.875 2.73857 29.875 5.5V25.5C29.875 28.2614 27.6401 30.5 24.8832 30.5H5.49183C2.73492 30.5 0.5 28.2614 0.5 25.5V5.5Z" fill="currentcolor"/>
<path d="M20.5608 21.9662H23.5V16.0287C23.5 13.0963 21.7277 12.0961 20.0877 12.0961C18.5711 12.0961 17.5409 13.1051 17.2565 13.6961V12.3659H14.4298V21.9662H17.369V16.7613C17.369 15.3734 18.224 14.6985 19.0961 14.6985C19.921 14.6985 20.5608 15.1756 20.5608 16.7227V21.9662ZM9.5 9.29365C9.5 10.2959 10.2587 11.0284 11.1945 11.0284C12.1305 11.0284 12.8892 10.2959 12.8892 9.29365C12.8892 8.2915 12.1305 7.55823 11.1945 7.55823C10.2587 7.55823 9.5 8.2915 9.5 9.29365ZM9.72497 21.9588H12.664V12.3585H9.72497V21.9588Z" fill="#F9F9F9"/>
</g>
<defs>
<clipPath id="clip0_488_42">
<rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>
                      </a>
                  </li>
                 
              </ul>
          </div>

      </div>
   
      
          
 
          </Popover.Panel>
          
          </Popover.Button>
          
        </>
      )}
    </Popover>
                        

</div>

<div className="relative max-w-xs mx-auto">
  <Popover>
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of the chevron icon. */
        <>
          <Popover.Button className={"w-full outline-none"}
          onClick={() => handleClick(open)}
          >
          <div 
          className={open ? 'w-full h-full bg-[#F89A33] absolute' : 'w-full h-full absolute'}
          onMouseEnter={(action) => onHover(open, "onMouseEnter",action)}
            onMouseLeave={() => onHover(open, "onMouseLeave")}
          >
                            
                        </div>
                        <div className="relative right-4 bottom-4">
                            <img className="w-full h-full relative"
                                src="images/team3.png" alt=""/>

                        </div>
                        
      <Popover.Panel className="w-full">
     
      <div 
          className={open ? 'absolute right-4 bottom-4 w-full h-full flex justify-center items-center bg-black/30' : 'absolute top-0 left-0 w-full flex justify-center items-center opacity-0'}
          >
            

            <div className="text-center text-gray-500 dark:text-gray-400">
              
              <span className="mb-1 team-name">
                  Shola Nejo
              </span>
              <p className="team-sub">Technical</p>
              <ul className="flex justify-center mt-4 space-x-4">
                  <li className='rounded-full'>
                  <a href="https://m.facebook.com/nejo.shola" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21575 23.7843 0.5 15.5 0.5C7.21575 0.5 0.5 7.21575 0.5 15.5C0.5 23.7843 7.21575 30.5 15.5 30.5Z" fill="currentcolor"/>
<path d="M20 12.1809H16.5944V10.5203C16.5944 9.89663 17.1503 9.75123 17.5419 9.75123C17.9326 9.75123 19.9452 9.75123 19.9452 9.75123V7.0096L16.6353 7C12.9611 7 12.1249 9.04479 12.1249 10.3533V12.1809H10V15.006H12.1249C12.1249 18.6315 12.1249 23 12.1249 23H16.5944C16.5944 23 16.5944 18.5885 16.5944 15.006H19.6102L20 12.1809Z" fill="#F9F9F9"/>
</svg>
                    </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="https://mobile.twitter.com/T_Major_" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 30.5001C23.7843 30.5001 30.5 23.7843 30.5 15.5C30.5 7.21574 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21574 0.5 15.5C0.5 23.7843 7.21573 30.5001 15.5 30.5001Z" fill="currentcolor"/>
<path d="M22.5 11.6597C21.9758 11.892 21.4196 12.0442 20.8502 12.1114C21.4501 11.7527 21.8991 11.188 22.1134 10.5227C21.5495 10.8572 20.9327 11.0929 20.2894 11.2195C20.0206 10.9328 19.6957 10.7044 19.335 10.5484C18.9743 10.3924 18.5853 10.3122 18.1923 10.3127C16.6064 10.3127 15.3201 11.5991 15.3201 13.185C15.3201 13.4102 15.3456 13.6293 15.3948 13.8397C13.0077 13.7199 10.8912 12.5765 9.47442 10.8382C9.21894 11.2766 9.08468 11.775 9.08542 12.2824C9.08542 13.2786 9.59287 14.1582 10.3632 14.6731C9.907 14.6591 9.46089 14.5357 9.06229 14.3135L9.06207 14.3499C9.06207 15.7416 10.0526 16.9024 11.3663 17.1661C11.1197 17.2333 10.8653 17.2672 10.6097 17.2669C10.4242 17.2669 10.2445 17.2492 10.0693 17.2158C10.4347 18.3566 11.4952 19.1871 12.7524 19.2104C11.7349 20.009 10.4783 20.4421 9.18481 20.44C8.95347 20.44 8.72427 20.4265 8.5 20.3997C9.81306 21.2439 11.3415 21.692 12.9026 21.6903C18.1857 21.6903 21.0749 17.3136 21.0749 13.5177C21.0749 13.3932 21.0721 13.2692 21.0665 13.1463C21.6291 12.7401 22.1145 12.2366 22.5 11.6597Z" fill="#F9F9F9"/>
</svg>
                      </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="https://www.linkedin.com/in/shola-nejo" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_488_42)">
<path d="M0.5 5.5C0.5 2.73857 2.73492 0.5 5.49183 0.5H24.8832C27.6401 0.5 29.875 2.73857 29.875 5.5V25.5C29.875 28.2614 27.6401 30.5 24.8832 30.5H5.49183C2.73492 30.5 0.5 28.2614 0.5 25.5V5.5Z" fill="currentcolor"/>
<path d="M20.5608 21.9662H23.5V16.0287C23.5 13.0963 21.7277 12.0961 20.0877 12.0961C18.5711 12.0961 17.5409 13.1051 17.2565 13.6961V12.3659H14.4298V21.9662H17.369V16.7613C17.369 15.3734 18.224 14.6985 19.0961 14.6985C19.921 14.6985 20.5608 15.1756 20.5608 16.7227V21.9662ZM9.5 9.29365C9.5 10.2959 10.2587 11.0284 11.1945 11.0284C12.1305 11.0284 12.8892 10.2959 12.8892 9.29365C12.8892 8.2915 12.1305 7.55823 11.1945 7.55823C10.2587 7.55823 9.5 8.2915 9.5 9.29365ZM9.72497 21.9588H12.664V12.3585H9.72497V21.9588Z" fill="#F9F9F9"/>
</g>
<defs>
<clipPath id="clip0_488_42">
<rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>
                      </a>
                  </li>
                 
              </ul>
          </div>

      </div>
   
      
          
 
          </Popover.Panel>
          
          </Popover.Button>
          
        </>
      )}
    </Popover>
                        

</div>
<div className="relative max-w-xs mx-auto">
  <Popover>
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of the chevron icon. */
        <>
          <Popover.Button className={"w-full outline-none"}
          onClick={() => handleClick(open)}
          >
          <div 
          className={open ? 'w-full h-full bg-[#F89A33] absolute' : 'w-full h-full absolute'}
          onMouseEnter={(action) => onHover(open, "onMouseEnter",action)}
            onMouseLeave={() => onHover(open, "onMouseLeave")}
          >
                            
                        </div>
                        <div className="relative right-4 bottom-4">
                            <img className="w-full h-full relative"
                                src="images/team4.png" alt=""/>

                        </div>
                        
      <Popover.Panel className="w-full">
     
      <div 
          className={open ? 'absolute right-4 bottom-4 w-full h-full flex justify-center items-center bg-black/30' : 'absolute top-0 left-0 w-full flex justify-center items-center opacity-0'}
          >
            

            <div className="text-center text-gray-500 dark:text-gray-400">
              
              <span className="mb-1 team-name">
              Esther Nkiruka Duruibe
              </span>
              <p className="team-sub">Marketing</p>
              <ul className="flex justify-center mt-4 space-x-4">
                  <li className='rounded-full'>
                  <a href="#/" className="text-[#204D78] hover:text-[#F89A33]">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21575 23.7843 0.5 15.5 0.5C7.21575 0.5 0.5 7.21575 0.5 15.5C0.5 23.7843 7.21575 30.5 15.5 30.5Z" fill="currentcolor"/>
<path d="M20 12.1809H16.5944V10.5203C16.5944 9.89663 17.1503 9.75123 17.5419 9.75123C17.9326 9.75123 19.9452 9.75123 19.9452 9.75123V7.0096L16.6353 7C12.9611 7 12.1249 9.04479 12.1249 10.3533V12.1809H10V15.006H12.1249C12.1249 18.6315 12.1249 23 12.1249 23H16.5944C16.5944 23 16.5944 18.5885 16.5944 15.006H19.6102L20 12.1809Z" fill="#F9F9F9"/>
</svg>
                    </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="https://twitter.com/nikkiesther_?s=21&t=awSMOavEpo0kLI109KC90Q" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 30.5001C23.7843 30.5001 30.5 23.7843 30.5 15.5C30.5 7.21574 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21574 0.5 15.5C0.5 23.7843 7.21573 30.5001 15.5 30.5001Z" fill="currentcolor"/>
<path d="M22.5 11.6597C21.9758 11.892 21.4196 12.0442 20.8502 12.1114C21.4501 11.7527 21.8991 11.188 22.1134 10.5227C21.5495 10.8572 20.9327 11.0929 20.2894 11.2195C20.0206 10.9328 19.6957 10.7044 19.335 10.5484C18.9743 10.3924 18.5853 10.3122 18.1923 10.3127C16.6064 10.3127 15.3201 11.5991 15.3201 13.185C15.3201 13.4102 15.3456 13.6293 15.3948 13.8397C13.0077 13.7199 10.8912 12.5765 9.47442 10.8382C9.21894 11.2766 9.08468 11.775 9.08542 12.2824C9.08542 13.2786 9.59287 14.1582 10.3632 14.6731C9.907 14.6591 9.46089 14.5357 9.06229 14.3135L9.06207 14.3499C9.06207 15.7416 10.0526 16.9024 11.3663 17.1661C11.1197 17.2333 10.8653 17.2672 10.6097 17.2669C10.4242 17.2669 10.2445 17.2492 10.0693 17.2158C10.4347 18.3566 11.4952 19.1871 12.7524 19.2104C11.7349 20.009 10.4783 20.4421 9.18481 20.44C8.95347 20.44 8.72427 20.4265 8.5 20.3997C9.81306 21.2439 11.3415 21.692 12.9026 21.6903C18.1857 21.6903 21.0749 17.3136 21.0749 13.5177C21.0749 13.3932 21.0721 13.2692 21.0665 13.1463C21.6291 12.7401 22.1145 12.2366 22.5 11.6597Z" fill="#F9F9F9"/>
</svg>
                      </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="https://www.linkedin.com/in/estherduruibe" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_488_42)">
<path d="M0.5 5.5C0.5 2.73857 2.73492 0.5 5.49183 0.5H24.8832C27.6401 0.5 29.875 2.73857 29.875 5.5V25.5C29.875 28.2614 27.6401 30.5 24.8832 30.5H5.49183C2.73492 30.5 0.5 28.2614 0.5 25.5V5.5Z" fill="currentcolor"/>
<path d="M20.5608 21.9662H23.5V16.0287C23.5 13.0963 21.7277 12.0961 20.0877 12.0961C18.5711 12.0961 17.5409 13.1051 17.2565 13.6961V12.3659H14.4298V21.9662H17.369V16.7613C17.369 15.3734 18.224 14.6985 19.0961 14.6985C19.921 14.6985 20.5608 15.1756 20.5608 16.7227V21.9662ZM9.5 9.29365C9.5 10.2959 10.2587 11.0284 11.1945 11.0284C12.1305 11.0284 12.8892 10.2959 12.8892 9.29365C12.8892 8.2915 12.1305 7.55823 11.1945 7.55823C10.2587 7.55823 9.5 8.2915 9.5 9.29365ZM9.72497 21.9588H12.664V12.3585H9.72497V21.9588Z" fill="#F9F9F9"/>
</g>
<defs>
<clipPath id="clip0_488_42">
<rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>
                      </a>
                  </li>
                 
              </ul>
          </div>

      </div>
   
      
          
 
          </Popover.Panel>
          
          </Popover.Button>
          
        </>
      )}
    </Popover>
                        

</div>
<div className="relative max-w-xs mx-auto">
  <Popover>
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of the chevron icon. */
        <>
          <Popover.Button className={"w-full outline-none"}
          onClick={() => handleClick(open)}
          >
          <div 
          className={open ? 'w-full h-full bg-[#F89A33] absolute' : 'w-full h-full absolute'}
          onMouseEnter={(action) => onHover(open, "onMouseEnter",action)}
            onMouseLeave={() => onHover(open, "onMouseLeave")}
          >
                            
                        </div>
                        <div className="relative right-4 bottom-4">
                            <img className="w-full h-full relative"
                                src="images/team5.png" alt=""/>

                        </div>
                        
      <Popover.Panel className="w-full">
     
      <div 
          className={open ? 'absolute right-4 bottom-4 w-full h-full flex justify-center items-center bg-black/30' : 'absolute top-0 left-0 w-full flex justify-center items-center opacity-0'}
          >
            

            <div className="text-center text-gray-500 dark:text-gray-400">
              
              <span className="mb-1 team-name">
              Temitope S. Olanipekun
              </span>
              <p className="team-sub">Product designer</p>
              <ul className="flex justify-center mt-4 space-x-4">
                  <li className='rounded-full'>
                  <a href="#/" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21575 23.7843 0.5 15.5 0.5C7.21575 0.5 0.5 7.21575 0.5 15.5C0.5 23.7843 7.21575 30.5 15.5 30.5Z" fill="currentcolor"/>
<path d="M20 12.1809H16.5944V10.5203C16.5944 9.89663 17.1503 9.75123 17.5419 9.75123C17.9326 9.75123 19.9452 9.75123 19.9452 9.75123V7.0096L16.6353 7C12.9611 7 12.1249 9.04479 12.1249 10.3533V12.1809H10V15.006H12.1249C12.1249 18.6315 12.1249 23 12.1249 23H16.5944C16.5944 23 16.5944 18.5885 16.5944 15.006H19.6102L20 12.1809Z" fill="#F9F9F9"/>
</svg>
                    </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="#/" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 30.5001C23.7843 30.5001 30.5 23.7843 30.5 15.5C30.5 7.21574 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21574 0.5 15.5C0.5 23.7843 7.21573 30.5001 15.5 30.5001Z" fill="currentcolor"/>
<path d="M22.5 11.6597C21.9758 11.892 21.4196 12.0442 20.8502 12.1114C21.4501 11.7527 21.8991 11.188 22.1134 10.5227C21.5495 10.8572 20.9327 11.0929 20.2894 11.2195C20.0206 10.9328 19.6957 10.7044 19.335 10.5484C18.9743 10.3924 18.5853 10.3122 18.1923 10.3127C16.6064 10.3127 15.3201 11.5991 15.3201 13.185C15.3201 13.4102 15.3456 13.6293 15.3948 13.8397C13.0077 13.7199 10.8912 12.5765 9.47442 10.8382C9.21894 11.2766 9.08468 11.775 9.08542 12.2824C9.08542 13.2786 9.59287 14.1582 10.3632 14.6731C9.907 14.6591 9.46089 14.5357 9.06229 14.3135L9.06207 14.3499C9.06207 15.7416 10.0526 16.9024 11.3663 17.1661C11.1197 17.2333 10.8653 17.2672 10.6097 17.2669C10.4242 17.2669 10.2445 17.2492 10.0693 17.2158C10.4347 18.3566 11.4952 19.1871 12.7524 19.2104C11.7349 20.009 10.4783 20.4421 9.18481 20.44C8.95347 20.44 8.72427 20.4265 8.5 20.3997C9.81306 21.2439 11.3415 21.692 12.9026 21.6903C18.1857 21.6903 21.0749 17.3136 21.0749 13.5177C21.0749 13.3932 21.0721 13.2692 21.0665 13.1463C21.6291 12.7401 22.1145 12.2366 22.5 11.6597Z" fill="#F9F9F9"/>
</svg>
                      </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="#/" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_488_42)">
<path d="M0.5 5.5C0.5 2.73857 2.73492 0.5 5.49183 0.5H24.8832C27.6401 0.5 29.875 2.73857 29.875 5.5V25.5C29.875 28.2614 27.6401 30.5 24.8832 30.5H5.49183C2.73492 30.5 0.5 28.2614 0.5 25.5V5.5Z" fill="currentcolor"/>
<path d="M20.5608 21.9662H23.5V16.0287C23.5 13.0963 21.7277 12.0961 20.0877 12.0961C18.5711 12.0961 17.5409 13.1051 17.2565 13.6961V12.3659H14.4298V21.9662H17.369V16.7613C17.369 15.3734 18.224 14.6985 19.0961 14.6985C19.921 14.6985 20.5608 15.1756 20.5608 16.7227V21.9662ZM9.5 9.29365C9.5 10.2959 10.2587 11.0284 11.1945 11.0284C12.1305 11.0284 12.8892 10.2959 12.8892 9.29365C12.8892 8.2915 12.1305 7.55823 11.1945 7.55823C10.2587 7.55823 9.5 8.2915 9.5 9.29365ZM9.72497 21.9588H12.664V12.3585H9.72497V21.9588Z" fill="#F9F9F9"/>
</g>
<defs>
<clipPath id="clip0_488_42">
<rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>
                      </a>
                  </li>
                 
              </ul>
          </div>

      </div>
   
      
          
 
          </Popover.Panel>
          
          </Popover.Button>
          
        </>
      )}
    </Popover>
                        

</div>
<div className="relative max-w-xs mx-auto">
  <Popover>
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of the chevron icon. */
        <>
          <Popover.Button className={"w-full outline-none"}
          onClick={() => handleClick(open)}
          >
          <div 
          className={open ? 'w-full h-full bg-[#F89A33] absolute' : 'w-full h-full absolute'}
          onMouseEnter={(action) => onHover(open, "onMouseEnter",action)}
            onMouseLeave={() => onHover(open, "onMouseLeave")}
          >
                            
                        </div>
                        <div className="relative right-4 bottom-4">
                            <img className="w-full h-full relative"
                                src="images/team6.png" alt=""/>

                        </div>
                        
      <Popover.Panel className="w-full">
     
      <div 
          className={open ? 'absolute right-4 bottom-4 w-full h-full flex justify-center items-center bg-black/30' : 'absolute top-0 left-0 w-full flex justify-center items-center opacity-0'}
          >
            

            <div className="text-center text-gray-500 dark:text-gray-400">
              
              <span className="mb-1 team-name">
              Mumen Elijah Osasumwen
              </span>
              <p className="team-sub">Product designer</p>
              <ul className="flex justify-center mt-4 space-x-4">
                  <li className='rounded-full'>
                  <a href="#/" className="text-[#204D78] hover:text-[#F89A33]">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21575 23.7843 0.5 15.5 0.5C7.21575 0.5 0.5 7.21575 0.5 15.5C0.5 23.7843 7.21575 30.5 15.5 30.5Z" fill="currentcolor"/>
<path d="M20 12.1809H16.5944V10.5203C16.5944 9.89663 17.1503 9.75123 17.5419 9.75123C17.9326 9.75123 19.9452 9.75123 19.9452 9.75123V7.0096L16.6353 7C12.9611 7 12.1249 9.04479 12.1249 10.3533V12.1809H10V15.006H12.1249C12.1249 18.6315 12.1249 23 12.1249 23H16.5944C16.5944 23 16.5944 18.5885 16.5944 15.006H19.6102L20 12.1809Z" fill="#F9F9F9"/>
</svg>
                    </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="https://twitter.com/osasumwen__" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 30.5001C23.7843 30.5001 30.5 23.7843 30.5 15.5C30.5 7.21574 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21574 0.5 15.5C0.5 23.7843 7.21573 30.5001 15.5 30.5001Z" fill="currentcolor"/>
<path d="M22.5 11.6597C21.9758 11.892 21.4196 12.0442 20.8502 12.1114C21.4501 11.7527 21.8991 11.188 22.1134 10.5227C21.5495 10.8572 20.9327 11.0929 20.2894 11.2195C20.0206 10.9328 19.6957 10.7044 19.335 10.5484C18.9743 10.3924 18.5853 10.3122 18.1923 10.3127C16.6064 10.3127 15.3201 11.5991 15.3201 13.185C15.3201 13.4102 15.3456 13.6293 15.3948 13.8397C13.0077 13.7199 10.8912 12.5765 9.47442 10.8382C9.21894 11.2766 9.08468 11.775 9.08542 12.2824C9.08542 13.2786 9.59287 14.1582 10.3632 14.6731C9.907 14.6591 9.46089 14.5357 9.06229 14.3135L9.06207 14.3499C9.06207 15.7416 10.0526 16.9024 11.3663 17.1661C11.1197 17.2333 10.8653 17.2672 10.6097 17.2669C10.4242 17.2669 10.2445 17.2492 10.0693 17.2158C10.4347 18.3566 11.4952 19.1871 12.7524 19.2104C11.7349 20.009 10.4783 20.4421 9.18481 20.44C8.95347 20.44 8.72427 20.4265 8.5 20.3997C9.81306 21.2439 11.3415 21.692 12.9026 21.6903C18.1857 21.6903 21.0749 17.3136 21.0749 13.5177C21.0749 13.3932 21.0721 13.2692 21.0665 13.1463C21.6291 12.7401 22.1145 12.2366 22.5 11.6597Z" fill="#F9F9F9"/>
</svg>
                      </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="https://www.linkedin.com/in/elijah-mumen-9097481b0" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_488_42)">
<path d="M0.5 5.5C0.5 2.73857 2.73492 0.5 5.49183 0.5H24.8832C27.6401 0.5 29.875 2.73857 29.875 5.5V25.5C29.875 28.2614 27.6401 30.5 24.8832 30.5H5.49183C2.73492 30.5 0.5 28.2614 0.5 25.5V5.5Z" fill="currentcolor"/>
<path d="M20.5608 21.9662H23.5V16.0287C23.5 13.0963 21.7277 12.0961 20.0877 12.0961C18.5711 12.0961 17.5409 13.1051 17.2565 13.6961V12.3659H14.4298V21.9662H17.369V16.7613C17.369 15.3734 18.224 14.6985 19.0961 14.6985C19.921 14.6985 20.5608 15.1756 20.5608 16.7227V21.9662ZM9.5 9.29365C9.5 10.2959 10.2587 11.0284 11.1945 11.0284C12.1305 11.0284 12.8892 10.2959 12.8892 9.29365C12.8892 8.2915 12.1305 7.55823 11.1945 7.55823C10.2587 7.55823 9.5 8.2915 9.5 9.29365ZM9.72497 21.9588H12.664V12.3585H9.72497V21.9588Z" fill="#F9F9F9"/>
</g>
<defs>
<clipPath id="clip0_488_42">
<rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>
                      </a>
                  </li>
                 
              </ul>
          </div>

      </div>
   
      
          
 
          </Popover.Panel>
          
          </Popover.Button>
          
        </>
      )}
    </Popover>
                        

</div>
<div className="relative max-w-xs mx-auto">
  <Popover>
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of the chevron icon. */
        <>
          <Popover.Button className={"w-full outline-none"}
          onClick={() => handleClick(open)}
          >
          <div 
          className={open ? 'w-full h-full bg-[#F89A33] absolute' : 'w-full h-full absolute'}
          onMouseEnter={(action) => onHover(open, "onMouseEnter",action)}
            onMouseLeave={() => onHover(open, "onMouseLeave")}
          >
                            
                        </div>
                        <div className="relative right-4 bottom-4">
                            <img className="w-full h-full relative"
                                src="images/team7.png" alt=""/>

                        </div>
                        
      <Popover.Panel className="w-full">
     
      <div 
          className={open ? 'absolute right-4 bottom-4 w-full h-full flex justify-center items-center bg-black/30' : 'absolute top-0 left-0 w-full flex justify-center items-center opacity-0'}
          >
            

            <div className="text-center text-gray-500 dark:text-gray-400">
              
              <span className="mb-1 team-name">
              Adele Nyeche
              </span>
              <p className="team-sub">Product designer</p>
              <ul className="flex justify-center mt-4 space-x-4">
                  <li className='rounded-full'>
                  <a href="#/" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21575 23.7843 0.5 15.5 0.5C7.21575 0.5 0.5 7.21575 0.5 15.5C0.5 23.7843 7.21575 30.5 15.5 30.5Z" fill="currentcolor"/>
<path d="M20 12.1809H16.5944V10.5203C16.5944 9.89663 17.1503 9.75123 17.5419 9.75123C17.9326 9.75123 19.9452 9.75123 19.9452 9.75123V7.0096L16.6353 7C12.9611 7 12.1249 9.04479 12.1249 10.3533V12.1809H10V15.006H12.1249C12.1249 18.6315 12.1249 23 12.1249 23H16.5944C16.5944 23 16.5944 18.5885 16.5944 15.006H19.6102L20 12.1809Z" fill="#F9F9F9"/>
</svg>
                    </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="#/" target="_blank" rel="noreferrer" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 30.5001C23.7843 30.5001 30.5 23.7843 30.5 15.5C30.5 7.21574 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21574 0.5 15.5C0.5 23.7843 7.21573 30.5001 15.5 30.5001Z" fill="currentcolor"/>
<path d="M22.5 11.6597C21.9758 11.892 21.4196 12.0442 20.8502 12.1114C21.4501 11.7527 21.8991 11.188 22.1134 10.5227C21.5495 10.8572 20.9327 11.0929 20.2894 11.2195C20.0206 10.9328 19.6957 10.7044 19.335 10.5484C18.9743 10.3924 18.5853 10.3122 18.1923 10.3127C16.6064 10.3127 15.3201 11.5991 15.3201 13.185C15.3201 13.4102 15.3456 13.6293 15.3948 13.8397C13.0077 13.7199 10.8912 12.5765 9.47442 10.8382C9.21894 11.2766 9.08468 11.775 9.08542 12.2824C9.08542 13.2786 9.59287 14.1582 10.3632 14.6731C9.907 14.6591 9.46089 14.5357 9.06229 14.3135L9.06207 14.3499C9.06207 15.7416 10.0526 16.9024 11.3663 17.1661C11.1197 17.2333 10.8653 17.2672 10.6097 17.2669C10.4242 17.2669 10.2445 17.2492 10.0693 17.2158C10.4347 18.3566 11.4952 19.1871 12.7524 19.2104C11.7349 20.009 10.4783 20.4421 9.18481 20.44C8.95347 20.44 8.72427 20.4265 8.5 20.3997C9.81306 21.2439 11.3415 21.692 12.9026 21.6903C18.1857 21.6903 21.0749 17.3136 21.0749 13.5177C21.0749 13.3932 21.0721 13.2692 21.0665 13.1463C21.6291 12.7401 22.1145 12.2366 22.5 11.6597Z" fill="#F9F9F9"/>
</svg>
                      </a>
                  </li>
                  <li className='rounded-full'>
                      <a href="#/" className="text-[#204D78] hover:text-[#F89A33]">
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_488_42)">
<path d="M0.5 5.5C0.5 2.73857 2.73492 0.5 5.49183 0.5H24.8832C27.6401 0.5 29.875 2.73857 29.875 5.5V25.5C29.875 28.2614 27.6401 30.5 24.8832 30.5H5.49183C2.73492 30.5 0.5 28.2614 0.5 25.5V5.5Z" fill="currentcolor"/>
<path d="M20.5608 21.9662H23.5V16.0287C23.5 13.0963 21.7277 12.0961 20.0877 12.0961C18.5711 12.0961 17.5409 13.1051 17.2565 13.6961V12.3659H14.4298V21.9662H17.369V16.7613C17.369 15.3734 18.224 14.6985 19.0961 14.6985C19.921 14.6985 20.5608 15.1756 20.5608 16.7227V21.9662ZM9.5 9.29365C9.5 10.2959 10.2587 11.0284 11.1945 11.0284C12.1305 11.0284 12.8892 10.2959 12.8892 9.29365C12.8892 8.2915 12.1305 7.55823 11.1945 7.55823C10.2587 7.55823 9.5 8.2915 9.5 9.29365ZM9.72497 21.9588H12.664V12.3585H9.72497V21.9588Z" fill="#F9F9F9"/>
</g>
<defs>
<clipPath id="clip0_488_42">
<rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>
                      </a>
                  </li>
                 
              </ul>
          </div>

      </div>
   
      
          
 
          </Popover.Panel>
          
          </Popover.Button>
          
        </>
      )}
    </Popover>
                        

</div>

  </div>
</div>

        </section>
    )
}
