/* This example requires Tailwind CSS v3.0+ */

import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";


class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
    };
    this.form = React.createRef();
  }

 

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //FirstName
    if (!fields["firstname"]) {
      formIsValid = false;
      errors["firstname"] = "Firstname Cannot be empty";
    }

    if (typeof fields["firstname"] !== "undefined") {
      if (!fields["firstname"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["firstname"] = "Name should contain only letters";
      }
    }

    //Name
    if (!fields["lastname"]) {
      formIsValid = false;
      errors["lastname"] = "Lastname Cannot be empty";
    }

    if (typeof fields["lastname"] !== "undefined") {
      if (!fields["lastname"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["lastname"] = "Name should contain only letters";
      }
    }

    //phone
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Phone cannot be empty";
    }

     //message
     if (!fields["message"]) {
      formIsValid = false;
      errors["message"] = "Message cannot be empty";
    }

    //message
    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = "Select a subject";
    }


    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email Cannot be empty";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  contactSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      emailjs
      .sendForm(
        "service_1bxrhij",
        "template_7jntgur",
        this.form.current,
        "k0m4f5lysi0rpsLi_"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message Sent");
          this.form.reset();
        },
        (error) => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
      
    } else {
      alert("Form has errors.");
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  render() {
    return (
      <section className="bg-white">
        <div className="bg-[#FBFBFB] md:px-20 py-6">
          <div className=" bg-white rounded-md px-6 py-10 max-w-6xl mx-auto">
            <div className="container mx-auto flex flex-col px-6 py-12">
              <div className="flex-1 lg:-mx-6 lg:flex lg:items-center">
                <div className='flex bg-[url("../public/images/cbg.svg")] rounded-lg w-full bg-cover bg-center bg-no-repeat mb-8 min-h-[650px] lg:w-1/2'>
  
                  <div className="flex flex-1 flex-col justify-between mx-10 my-10">
                    <div>
  
                      <p className="mt-6 text-2xl md:text-4xl contact-page-header">Contact Information</p>
                      <h1 className="text-base contact-page-subheader">Let us know how we can be of assistance </h1>
                    </div>
                    <div>
                      <div className="flex items-start ">
                        <div className="flex h-12 w-12 items-center justify-center rounded-full">
                          <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 16.0347C1.45 16.0347 0.979002 15.839 0.587002 15.4477C0.195002 15.0563 -0.000664969 14.5853 1.69779e-06 14.0347V2.03467C1.69779e-06 1.48467 0.196002 1.01367 0.588002 0.62167C0.980002 0.22967 1.45067 0.034003 2 0.0346697H18C18.55 0.0346697 19.021 0.230669 19.413 0.622669C19.805 1.01467 20.0007 1.48534 20 2.03467V14.0347C20 14.5847 19.804 15.0557 19.412 15.4477C19.02 15.8397 18.5493 16.0353 18 16.0347H2ZM10 8.85967C10.0833 8.85967 10.171 8.84734 10.263 8.82267C10.355 8.798 10.4423 8.76034 10.525 8.70967L17.6 4.28467C17.7333 4.20134 17.8333 4.09734 17.9 3.97267C17.9667 3.848 18 3.71034 18 3.55967C18 3.22634 17.8583 2.97634 17.575 2.80967C17.2917 2.643 17 2.65134 16.7 2.83467L10 7.03467L3.3 2.83467C3 2.65134 2.70834 2.64734 2.425 2.82267C2.14167 2.998 2 3.24367 2 3.55967C2 3.72634 2.03333 3.87234 2.1 3.99767C2.16667 4.123 2.26667 4.21867 2.4 4.28467L9.475 8.70967C9.55834 8.75967 9.646 8.79734 9.738 8.82267C9.83 8.848 9.91734 8.86034 10 8.85967Z" fill="white" />
                          </svg>
                        </div>
  
                        <div className="ml-4 mb-6">
                          <p className="mt-2 contact-link">hello@zibon.ng</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="-mx-1.5 mt-4 flex">
                        <a className="mx-1.5 rounded-full bg-[#1877F2] text-[#F89A33] transition-colors duration-300 hover:text-black" target="_blank" href="https://twitter.com/Zibonhq">
                          <svg className="h-10 w-10 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.6668 6.67334C18.0002 7.00001 17.3468 7.13268 16.6668 7.33334C15.9195 6.49001 14.8115 6.44334 13.7468 6.84201C12.6822 7.24068 11.9848 8.21534 12.0002 9.33334V10C9.83683 10.0553 7.91016 9.07001 6.66683 7.33334C6.66683 7.33334 3.87883 12.2887 9.3335 14.6667C8.0855 15.498 6.84083 16.0587 5.3335 16C7.53883 17.202 9.94216 17.6153 12.0228 17.0113C14.4095 16.318 16.3708 14.5293 17.1235 11.85C17.348 11.0351 17.4595 10.1932 17.4548 9.34801C17.4535 9.18201 18.4615 7.50001 18.6668 6.67268V6.67334Z" />
                          </svg>
                        </a>
  
                        <a className="mx-1.5 rounded-full bg-[#1877F2]  text-[#F89A33] transition-colors duration-300 hover:text-black" target="_blank" href="https://instagram.com/getzibonapp_">
                          <svg className="h-10 w-10 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9294 7.72275C9.65868 7.72275 7.82715 9.55428 7.82715 11.825C7.82715 14.0956 9.65868 15.9271 11.9294 15.9271C14.2 15.9271 16.0316 14.0956 16.0316 11.825C16.0316 9.55428 14.2 7.72275 11.9294 7.72275ZM11.9294 14.4919C10.462 14.4919 9.26239 13.2959 9.26239 11.825C9.26239 10.354 10.4584 9.15799 11.9294 9.15799C13.4003 9.15799 14.5963 10.354 14.5963 11.825C14.5963 13.2959 13.3967 14.4919 11.9294 14.4919ZM17.1562 7.55495C17.1562 8.08692 16.7277 8.51178 16.1994 8.51178C15.6674 8.51178 15.2425 8.08335 15.2425 7.55495C15.2425 7.02656 15.671 6.59813 16.1994 6.59813C16.7277 6.59813 17.1562 7.02656 17.1562 7.55495ZM19.8731 8.52606C19.8124 7.24434 19.5197 6.10901 18.5807 5.17361C17.6453 4.23821 16.51 3.94545 15.2282 3.88118C13.9073 3.80621 9.94787 3.80621 8.62689 3.88118C7.34874 3.94188 6.21341 4.23464 5.27444 5.17004C4.33547 6.10544 4.04628 7.24077 3.98201 8.52249C3.90704 9.84347 3.90704 13.8029 3.98201 15.1238C4.04271 16.4056 4.33547 17.5409 5.27444 18.4763C6.21341 19.4117 7.34517 19.7045 8.62689 19.7687C9.94787 19.8437 13.9073 19.8437 15.2282 19.7687C16.51 19.708 17.6453 19.4153 18.5807 18.4763C19.5161 17.5409 19.8089 16.4056 19.8731 15.1238C19.9481 13.8029 19.9481 9.84704 19.8731 8.52606ZM18.1665 16.5412C17.8881 17.241 17.349 17.7801 16.6456 18.0621C15.5924 18.4799 13.0932 18.3835 11.9294 18.3835C10.7655 18.3835 8.26272 18.4763 7.21307 18.0621C6.51331 17.7837 5.9742 17.2446 5.69215 16.5412C5.27444 15.488 5.37083 12.9888 5.37083 11.825C5.37083 10.6611 5.27801 8.15832 5.69215 7.10867C5.97063 6.40891 6.50974 5.8698 7.21307 5.58775C8.26629 5.17004 10.7655 5.26643 11.9294 5.26643C13.0932 5.26643 15.596 5.17361 16.6456 5.58775C17.3454 5.86623 17.8845 6.40534 18.1665 7.10867C18.5843 8.16189 18.4879 10.6611 18.4879 11.825C18.4879 12.9888 18.5843 15.4916 18.1665 16.5412Z" fill="currentColor" />
                          </svg>
                        </a>
  
                        <a className="mx-1.5 rounded-full bg-[#1877F2] transform text-[#F89A33] transition-colors duration-300 hover:text-black" target="_blank" href="https://www.facebook.com/zibonhq">
                          <svg className="h-10 w-10 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 10.2222V13.7778H9.66667V20H13.2222V13.7778H15.8889L16.7778 10.2222H13.2222V8.44444C13.2222 8.2087 13.3159 7.9826 13.4826 7.81591C13.6493 7.64921 13.8754 7.55556 14.1111 7.55556H16.7778V4H14.1111C12.9324 4 11.8019 4.46825 10.9684 5.30175C10.1349 6.13524 9.66667 7.2657 9.66667 8.44444V10.2222H7Z" fill="currentColor" />
                          </svg>
                        </a>
  
                       
                      </div>
                    </div>
                  </div>
  
  
                </div>
  
                <div className="lg:mx-6 lg:w-1/2 pt-5">
                  <div className="mx-auto w-full overflow-hidden rounded-xl bg-white lg:px-8 lg:max-w-xl">
  
                    <form 
                     ref={this.form}
                    name="contactform"
                    className="contactform"
                    onSubmit={this.contactSubmit.bind(this)}
                    >
                      <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-2">
                          <label className="contact-label" for="grid-first-name">
                            First Name*
                          </label>
                          <input 
                          className="w-full p-2 text-sm border-0 border-b-2 border-gray-400 outline-none opacity-50 focus:border-black  focus:border-b-2 focus:ring-0" 
                          id="grid-first-name"
                          name="firstname"
                           type="text" 
                           placeholder="" 
                           onChange={this.handleChange.bind(this, "firstname")}
                           value={this.state.fields["firstname"]}
                           />
  <span style={{ color: "red" }}>{this.state.errors["firstname"]}</span>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label className="contact-label" for="grid-last-name">
                            Last Name*
                          </label>
                          <input 
                          className="w-full p-2 text-sm border-0 border-b-2 border-gray-400 outline-none opacity-50 focus:border-black  focus:border-b-2 focus:ring-0" 
                          id="grid-first-name" 
                          name="lastname"
                          type="text" 
                          placeholder=""
                          onChange={this.handleChange.bind(this, "lastname")}
                           value={this.state.fields["lastname"]}
                          />
                          <span style={{ color: "red" }}>{this.state.errors["lastname"]}</span>
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-2">
                          <label className="contact-label" for="grid-first-name">
                            Email*
                          </label>
                          <input 
                          className="w-full p-2 text-sm border-0 border-b-2 border-gray-400 outline-none opacity-50 focus:border-black  focus:border-b-2 focus:ring-0" 
                          id="grid-first-name" 
                          type="text" 
                          name="email"
                          placeholder="" 
                          onChange={this.handleChange.bind(this, "email")}
                           value={this.state.fields["email"]}
                          />
                          <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-2">
                          <label className="contact-label" for="grid-last-name">
                            Phone Number*
                          </label>
                          <input 
                          className="w-full p-2 text-sm border-0 border-b-2 border-gray-400 outline-none opacity-50 focus:border-black  focus:border-b-2 focus:ring-0" 
                          id="grid-first-name" 
                          name="phone"
                          type="text"
                           placeholder="" 
                           onChange={this.handleChange.bind(this, "phone")}
                           value={this.state.fields["phone"]}
                           />
                           <span style={{ color: "red" }}>{this.state.errors["phone"]}</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <label className="mb-2 contact-label">Subject*</label>
                        <select id="underline_select"
                        name="subject" 
                        className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                        onChange={this.handleChange.bind(this, "subject")}
                           value={this.state.fields["subject"]}
                        >
                          <option selected>Select a subject</option>
                          <option value="GI">General Inquiry</option>
  
  
                        </select>
                        <span style={{ color: "red" }}>{this.state.errors["subject"]}</span>
                      </div>
  
  
                      <div className="mt-6 w-full">
                        <label className="mb-2 contact-label">Message*</label>
                        <textarea 
                        name="message"
                        className="mt-2 block h-10 w-full bg-white px-5 py-3 border-0 border-b-2 border-gray-400 outline-none opacity-50 focus:border-black  focus:border-b-2 focus:ring-0 md:h-10"
                         placeholder=""
                         onChange={this.handleChange.bind(this, "message")}
                           value={this.state.fields["message"]}
                           
                         ></textarea>
                         <span style={{ color: "red" }}>{this.state.errors["message"]}</span>
                      </div>
  
                      <div className="container  mt-6 mx-0 text-right">
                        <button className="transform rounded-md bg-[#F89A33] px-6 py-3 text-sm font-medium capitalize tracking-wide text-white transition-colors duration-300  focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-50">Send Message</button>
                        <div className="grid hidde grid-cols-1 gap-4">
                          <img className=" rounded-lg mx-auto" src="images/letter_send.svg" alt="who we are" />
                        </div>
                      </div>
  
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
  
      </section>
    )
  }
}

export default ContactPage;