import { Disclosure } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";


export default function Accordion() {
    let timeout // NodeJS.Timeout
    const timeoutDuration = 400

    const buttonRef = useRef(null) // useRef<HTMLButtonElement>(null)
    const [openState] = useState(false)

   

    const toggleMenu = (target) => {
        // log the current open state in React (toggle open state)
        //setOpenState((openState) => !openState)
        // toggle the menu by clicking on buttonRef
        target?.current?.click() // eslint-disable-line
    }

    // Open the menu after a delay of timeoutDuration
    const onHover = (open,target, action) => {
        console.log(open,action);
        // if the modal is currently closed, we need to open it
        // OR
        // if the modal is currently open, we need to close it
        if (
            (!open && !openState && action === "onMouseEnter") ||
            (open && openState && action === "onMouseLeave")
        ) {
            // clear the old timeout, if any
            clearTimeout(timeout)
            // open the modal after a timeout
            timeout = setTimeout(() => toggleMenu(target,open), timeoutDuration)
        }
        // else: don't click!
    }
    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target)) {
          event.stopPropagation()
        }
      }
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside)
        }
      })
    return (
        <div id="faq" className="w-full bg-white px-4 py-16">
            <div className="pt-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-16 lg:px-12">

                <h1 className="mb-4 faq-header">FAQ's</h1>
                <p className="mb-4 faq-subtitle">Questions?? We’ve got answers</p>

            </div>
            <div className="w-full md:w-[80%] p-2 mx-auto">
                <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                        <>
                            <Disclosure.Button 
                            ref={buttonRef}
                            onMouseEnter={() => onHover(open, "onMouseEnter")}
                            onMouseLeave={() => onHover(open, "onMouseLeave")}
                            onClick={() => toggleMenu(open)}
                             className="flex justify-between w-full px-4 py-3 text-sm font-medium text-left faq-dropdown-btn focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                <div className="grid grid-cols-[40px_auto]">
                                <span className="text-xl sm:text-3xl faq-no">01</span>
                                    <span className="text-xl sm:text-3xl faq-text">Why choose us?</span>
                                </div>
                              
                                    
                                 
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className={`${open ? "rotate-180 transform" : ""
                                        } h-5 w-5 text-purple-500`}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-10 faq-box">
                                The app enables you to support local businesses, you can help to keep money within your community and support small businesses that may not have the resources to compete with larger companies. Additionally, purchasing items locally can help to reduce the environmental impact associated with shipping and transportation.
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="mt-2"
                    
                >
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-3 text-sm font-medium text-left faq-dropdown-btn focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                             ref={buttonRef}
                             onMouseEnter={() =>  (open, "onMouseEnter")}
                             onMouseLeave={() => onHover(open, "onMouseLeave")}
                             onClick={({target}) => toggleMenu(target,open)}
                            >
                                <div className="grid grid-cols-[40px_auto]">
                                     <span className="text-xl sm:text-3xl faq-no">02</span>
                                <span className="text-xl sm:text-3xl faq-text">How does the app work?</span>
                                </div>
                              
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className={`${open ? "rotate-180 transform" : ""
                                        } h-5 w-5 text-purple-500`}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-10 faq-box">
                                The app allows vendors to post items they are selling and enables users to browse these listings using their location. The app also has features that help to facilitate community engagement, such as forums and events.
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                        <>
                            <Disclosure.Button 
                            ref={buttonRef}
                            onMouseEnter={() => onHover(open, "onMouseEnter")}
                            onMouseLeave={() => onHover(open, "onMouseLeave")}
                            onClick={() => toggleMenu(open)}
                             className="flex justify-between w-full px-4 py-3 text-sm font-medium text-left faq-dropdown-btn focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                <div className="grid grid-cols-[40px_auto]">
                                <span className="text-xl sm:text-3xl faq-no">03</span>
                                    <span className="text-xl sm:text-3xl faq-text">What is the benefit of using Zibon?</span>
                                </div>
                              
                                    
                                 
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className={`${open ? "rotate-180 transform" : ""
                                        } h-5 w-5 text-purple-500`}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-10 faq-box">
                             By using a social commerce app that brings communities together, you can connect with people in your local area who may be selling unique or hard-to-find items that you would not have known about otherwise. This can help you to discover new things and support local businesses.
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="mt-2"
                    
                >
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-3 text-sm font-medium text-left faq-dropdown-btn focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                             ref={buttonRef}
                             onMouseEnter={() =>  (open, "onMouseEnter")}
                             onMouseLeave={() => onHover(open, "onMouseLeave")}
                             onClick={({target}) => toggleMenu(target,open)}
                            >
                                <div className="grid grid-cols-[40px_auto]">
                                     <span className="text-xl sm:text-3xl faq-no">04</span>
                                <span className="text-xl sm:text-3xl faq-text">Is it safe to buy items from individuals on this app?</span>
                                </div>
                              
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className={`${open ? "rotate-180 transform" : ""
                                        } h-5 w-5 text-purple-500`}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-10 faq-box">
                            The app typically has measures in place to ensure the safety of its users, such as user verification and the escrow feature that holds the payment for a transaction until both parties have completed their part of the deal, thereby ensuring that both parties fulfill their obligations before the payment is released.
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                        <>
                            <Disclosure.Button 
                            ref={buttonRef}
                            onMouseEnter={() => onHover(open, "onMouseEnter")}
                            onMouseLeave={() => onHover(open, "onMouseLeave")}
                            onClick={() => toggleMenu(open)}
                             className="flex justify-between w-full px-4 py-3 text-sm font-medium text-left faq-dropdown-btn focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                <div className="grid grid-cols-[40px_auto]">
                                <span className="text-xl sm:text-3xl faq-no">05</span>
                                    <span className="text-xl sm:text-3xl faq-text">What types of items can I find on this app?</span>
                                </div>
                              
                                    
                                 
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className={`${open ? "rotate-180 transform" : ""
                                        } h-5 w-5 text-purple-500`}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-10 faq-box">
                            The types of items available on the app will depend on the sellers in your local area. However, you can typically find a wide range of items, from handmade crafts to secondhand clothing to unique vintage finds, etc.
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="mt-2"
                    
                >
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-3 text-sm font-medium text-left faq-dropdown-btn focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                             ref={buttonRef}
                             onMouseEnter={() =>  (open, "onMouseEnter")}
                             onMouseLeave={() => onHover(open, "onMouseLeave")}
                             onClick={({target}) => toggleMenu(target,open)}
                            >
                                <div className="grid grid-cols-[40px_auto]">
                                     <span className="text-xl sm:text-3xl faq-no">06</span>
                                <span className="text-xl sm:text-3xl faq-text">How can I get involved in my local community through the app?</span>
                                </div>
                              
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className={`${open ? "rotate-180 transform" : ""
                                        } h-5 w-5 text-purple-500`}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-10 faq-box">
                            The app has features such as forums, groups, or events that allow you to connect with other users in your local area. By participating in these features, you can meet new people, discover new businesses, and become more involved in your community.
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    );
}