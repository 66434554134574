/* This example requires Tailwind CSS v3.0+ */

import { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';



export default function WhoWeAre() {
   const [showMore,setShowMore] = useState(false)
   const text = "We are a community-focused social commerce company, connecting customers with local vendors to provide access to the products they need. Our platform is designed to showcase the unique offerings of small businesses and independent vendors in your neighborhood you never knew existed, making it easy for you to discover and support local businesses. Our goal is to create a sense of community, where customers can get to know their local vendors, learn about their products, and build relationships with the people who provide them with goods and services. Whether you're looking for household items, handmade crafts, unique gifts, or anything in between, we bring the best of your neighborhood right to your fingertips. With us, you can support local businesses, discover new products, and build a stronger community, all while getting the items you need."
    return (
        <section className="bg-white">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-3xl sm:text-5xl who-header">Who We Are</h2>
                    <p className="text-xs sm:text-base who-text"> {showMore? text: `${text.substring(0,344)}`}</p>
                    <div className='mb-8 flex items-end'>
                    <button  onClick={()=>setShowMore(!showMore)} className="float-right w-full text-xs sm:text-base read-more">{showMore? "Show less":"Read more"}</button>
                    </div>
                    
                    <a href="/#waitlist" className="primary-btn inline-flex justify-center items-center text-base text-center rounded-lg">
                Join Waitlist
            </a>  
                </div>
                <div className="grid grid-cols-1 gap-4 mt-8">
                    <img className=" rounded-lg mx-auto" src="images/who.png" alt="who we are"/>
                </div>
            </div>
        </section>
    )
}
