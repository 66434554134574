import { useEffect, useRef, useState } from 'react';
import { decode } from 'html-entities';

const NewsletterFooterForm = ( { status, message, onValidated }) => {

  const [ error, setError ] = useState(null);
  const [ email, setEmail ] = useState(null);

  const myform = useRef();
  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setError(null);

    if ( ! email ) {
      setError('Please enter a valid email address');
     
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });
    // On success return true
    
    return email && email.indexOf("@") > -1 && isFormValidated;
  }

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = ( event ) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  }

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if ( !message ) {
     return null;
    }
    const result = message?.split('-') ?? null;
    if ( "0" !== result?.[0]?.trim() ) {
     return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode( formattedMessage ) : null;
  }
  useEffect(() => {
    if (status === "success"){
      //const id = setTimeout(() => {
     myform.current.reset()
   // }, 5000);
    }
})
  return (
    <>
    <div className="newsletter-form-info mt-10 mb-4">
        {status === "sending" && <div className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3">Sending...</div>}
        {status === "error" || error ? (
         
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert"
   dangerouslySetInnerHTML={{ __html: error || getMessage( message ) }}
/>
        ) : null }
        {status === "success" && status !== "error" && !error && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" dangerouslySetInnerHTML={{ __html: decode(message) }} />
        )}
      </div> 
      <div className="flex max-w-sm mr-10 bg-white p-1 rounded-lg">
       
      <form ref={myform} className="flex w-full items-center justify-center">
       
    <input 
   
    id="website-admin" 
    className="rounded-none rounded-l-lg focus:border-none focus:outline-none border-none outline-none border-transparent focus:border-transparent focus:ring-0 text-gray-900 block flex-1 min-w-0 w-full text-sm p-2.5" 
    placeholder="Your email address"
    onChange={(event) => setEmail(event?.target?.value ?? '')}
    type="email"
    onKeyUp={(event) => handleInputKeyEvent(event)}
    required
    />
    <button type='submit' onClick={handleFormSubmit} className="flex items-center bg-[#204D78] justify-center w-12 h-11 text-white rounded-r-lg"
            >
            <svg className="w-6 h-6 rotate-90" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path></svg>
        </button>
        </form>
</div>
        
    </>
    
        
   
    
  );
}

export default NewsletterFooterForm